.verify {
	/* border: 1px solid blue; */
	min-height: 100vh;
	padding: 4rem 4rem 2.5rem 4rem;
	display: flex;
	gap: 2rem;
	flex-direction: column;
	/* justify-content: space-between; */
	width: 100%;
	position: relative;
}

.verify__back {
	/* border: 1px solid magenta; */
	width: 1.8rem;
	height: 1.8rem;
}

.verify__back svg {
	width: 100%;
	height: 100%;
}

.verify__container {
	/* border: 1px solid magenta; */
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.verify__otp {
	/* border: 1px solid magenta; */
	display: flex;
	flex-direction: column;
	gap: 2.5rem;
	justify-content: center;
	align-items: center;
}

.verify__otp .verify__title {
	font-size: var(--f-size-1);
	font-weight: var(--f-weight-1);
}

.verify__otp .verify__info {
	/* border: 1px solid magenta; */
	/* margin-top: 2.5rem; */
	background-color: var(--c-white-2);
	padding: .6rem 1rem;
	border-radius: .6rem;
	display: flex;
	gap: 1rem;
	font-size: var(--f-size-5);
	font-family: var(--f-family-2);
}

.verify__otp .verify__info .verify-info__icon {
	opacity: .6;
}

.verify__otp .verify__info p {
	flex: 1;
	/* opacity: .6; */
	color: rgba(255, 255, 255, 0.6);
	line-height: 1.6rem;
}

.verify__otp .verify__info p span {
	opacity: 1;
	color: var(--c-white);
}

.inputStyle {
 	/* border: 1px solid magenta; */
	margin: .6rem;
	width: 4rem !important;
	height: 5rem !important;
	border-radius: .6rem;
	border: 1px solid var(--c-white);
	background-color: transparent;
	color: var(--c-white);
}

.verify__otp-column {
	/* border: 1px solid magenta; */
}

/* .verify__button {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: var(--f-size-3);
	font-weight: var(--f-weight-1);
	color: var(--c-yellow);
	background: rgba(255, 199, 0, 0.1);
	border-radius: .6rem;
	padding: 1.55rem 0;
	width: 100%;
	max-width: 28rem;
	border: none;
} */

.verify__resend {
	/* border: 1px solid magenta; */
	font-family: var(--f-family-2);
	font-size: var(--f-size-5);
}

.verify__resend span {
	/* border: 1px solid magenta; */
	color: var(--c-yellow);
}

.verify__skip {
	/* border: 1px solid magenta; */
	padding: 0 1.5rem;
	text-align: center;
	color: rgba(255, 255, 255, 0.6);
	font-size: var(--f-size-3);
	font-weight: var(--f-weight-1);

}



