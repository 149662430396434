.loi-prev {
	/* border: 1px solid magenta; */
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.loi-prev__body {
	flex: 1;
	/* border: 1px solid green; */
	overflow-y: auto;
}

.loi-prev__box {
	/* border: 1px solid magenta; */
	height: 7rem;
	background-color: black;
	padding: 0 2rem;
	display: flex;
	align-items: center;
}

.loi-prev__box button {
	height: 4rem;
	width: 100%;
	border: none;
	outline: none;
	border-radius: .6rem;
	font-family: var(--f-family-1);
	font-size: var(--f-size-4);
	font-weight: var(--f-weight-1);
	color: var(--c-yellow);
	background-color: #FFC7001A;
}