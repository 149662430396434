.manage-listing__main {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.manage-listing__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3rem 2rem 1rem;
  gap: 2rem;
  background: #1f2944;
  box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.25);
}

.manage-listing__header-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.6rem;
}

.manage-listing__header-title svg {
  width: 2rem;
  height: 2rem;
}

.manage-listing__header-title h2 {
  font-weight: 700;
  font-size: 2rem;
}

.manage-listing__body {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 2rem;
  padding-bottom: 8.8rem;
  gap: 1.6rem;
  height: calc(100vh - 64px);
  flex: 1 0 auto;
  overflow-y: scroll;
}

.manage-listing__body-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.1rem 1.4rem;
  gap: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.6rem;
  width: 100%;
}

.body-container-timeslots {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.manage-listing__body-container,
.text-lg p {
  font-weight: 700;
}

.timeslotBtn {
  padding: 1.2rem 1.4rem;
  border-radius: 0.6rem;
  border: 0;
  background: rgba(255, 199, 0, 0.1);
  color: var(--c-yellow);
  font-weight: 700;
  font-size: 1.4rem;
}

.body-container-billing {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
}

.billing-switches {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
}

.billing-switches-services {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-weight: 700;
  gap: 0.6rem;
  align-items: center;
}

.billing-switches-services p {
  max-width: 10rem;
}

.services-option {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
}

.services-option input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 0.1rem;
  width: 0.1rem;
  border: 0;
  overflow: hidden;
}

.services-option label {
  background-color: rgba(255, 255, 255, 0.05);
  color: #bbbbbb;
  font-size: 1.2rem;
  font-weight: var(--f-weight-1);
  line-height: 1;
  text-align: center;
  padding: 0.6rem;
  margin-right: -0.1rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.services-option label:first-of-type {
  border-radius: 0.4rem 0 0 0.4rem;
}

.services-option label:last-of-type {
  border-radius: 0 0.4rem 0.4rem 0;
}

.services-option label:hover {
  cursor: pointer;
}

.services-option input:checked + label {
  background-color: #ffc700;
  color: #1f2944;
  font-weight: var(--f-weight-1);
}

.body-container-permissions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.permissions-radio-container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.permissions-radio-option {
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  align-items: center;
}

.permissions-radio-option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-option-container {
  position: relative;
  padding-left: 2rem;
  padding-top: 0.2rem;
  cursor: pointer;
}

.radio-option-container input:checked ~ .radio-custom {
  color: #ffc700;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.4rem;
  width: 1.4rem;
  background-color: transparent;
  border-radius: 100%;
  border: 0.1rem solid white;
}

.radio-option-container input:checked ~ .checkmark {
  background-color: transparent;
  border: 0.1rem solid #ffc700;
}

.checkmark::after {
  content: '';
  position: absolute;
  display: none;
}

.radio-option-container input:checked ~ .checkmark::after {
  display: block;
}

.radio-option-container .checkmark::after {
  top: 0.2rem;
  left: 0.2rem;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 100%;
  background: #ffc700;
}

.permissions__remove-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.6rem;
}

.permissions__remove-button button {
  border: none;
  background: transparent;
  font-size: 1.4rem;
  color: white;
  font-weight: 700;
  width: 100%;
  padding: 1.2rem 1.4rem;
}

.permissions__save-button {
  position: absolute;
  display: inline-block;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: var(--c-black-2);
}

.body-container-timeslots-schedule {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 0.6rem 1.2rem;
  gap: 1.7rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.6rem;
}

.body-container-timeslots-schedule-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.9rem;
}

.timeslots-schedule-details-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.6rem;
}

.date__day {
  padding: 0.3rem 0.8rem;
  background: rgba(255, 199, 0, 0.1);
  border-radius: 99px;
  color: #ffc700;
}

.date__date-month {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffc700;
  font-weight: 500;
}

.timeslots-schedule-details-time {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  font-weight: 500;
}

.timeslots-schedule-details-time .capacity {
  opacity: 60%;
}

.body-container-timeslots-schedule-action {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.2rem;
}

.body-container-timeslots-schedule-action svg {
  width: 0.9em;
  height: 0.9rem;
  cursor: pointer;
}
