.popupsys {
	position: fixed;
	top: 0; left: 0; right: 0; bottom: 0;
	/* border: 1px solid magenta; */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
	pointer-events: none;
	opacity: 0;
	transition: all .3s ease;
}

.popupsys--active {
	opacity: 1;
	transition: all .3s ease;
	pointer-events: none;
}

.popupsys .popupsys__box {
	position: absolute;
	top: 2rem;
	z-index: 1;
	/* border: 1px solid magenta; */
	width: 100%;
	max-width: 32rem;
	padding: 2rem;
	background-color: #26282E;
	border-radius: .6rem;
	display: flex;
	flex-direction: column;
	gap: 1.4rem;
}

.popupsys .popupsys__box p {
	font-size: var(--f-size-3);
	font-weight: var(--f-weight-1);
	color: var(--c-white);
}