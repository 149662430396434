.tenant-details {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.tenant-details__header {
  width: 100%;
  position: relative;
  /* border: 1px solid blue; */
}

.tenant-details .head__sub-tab{
  background-color: #161D30;
}

.tenant-details__header-back {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  z-index: 2;
  cursor: pointer;
}

.tenant-detail__header-image-slider {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tenant-detail__header-image-slider img {
  /* height: 30rem; */
  aspect-ratio: 6 / 5;
}

.tenant-detail__header-image-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 9rem;
  display: flex;
  align-items: flex-end;
  padding: 1rem 1.5rem;
  pointer-events: none;
  background: linear-gradient(180deg, rgba(31, 41, 68, 0) 0%, #080b12 100%);
}

.tenant-detail__header-image-info-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  pointer-events: none;
}

.tenant-detail__header-image-info-wrapper div:nth-child(1),
.tenant-detail__header-image-info-wrapper div:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  font-size: var(--f-size-4);
  font-weight: var(--f-weight-1);
  color: var(--c-white-3);
}

.tenant-detail__header-image-info-wrapper div:nth-child(1) {
  font-weight: var(--f-weight-2);
  font-family: var(--f-family-2);
}

.tenant-detail__header-image-info-wrapper div:nth-child(1) svg path,
.tenant-detail__header-image-info-wrapper div:nth-child(2) svg path {
  fill: var(--c-white-3);
}

.tenant-details .details__wrapper {
  /* border: 1px solid magenta; */
  flex: 1;
/* background-color: red; */
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  
}

.tenant-details .details__wrapper .details__head {
  /* border: 1px solid magenta; */
  z-index: 2;
}

.tenant-details__body {
  /* padding-bottom: 6.8rem; */
  /* flex: 1;background-color: red; */
  /* height: calc(100vh - (22.6rem + 7rem)); */
  overflow-y: auto;
}

.detail-browse__icons-container .button__sub-tab--browse {
  color: var(--c-white-1);
  border-radius: .6rem;
}

.detail-browse__icons-container .button__sub-tab--browse .search-browse__svg path {
  fill: white;
}

.tenant-details__footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem 2rem;
  gap: 1rem;
  width: 100%;
  /* position: absolute; */
  /* bottom: 0; */
  /* right: 0; */
  /* left: 0; */
  background-color: var(--c-black-2);
  border-radius: 0.6rem 0.6rem 0 0;
}

.tenant-details-button,
.tenant-details-offerBtn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 1.4rem;
  gap: 0.8rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.6rem;
  border: 0;
}

.tenant-details-button {
  color: white;
  background: rgba(255, 255, 255, 0.05);
}

.tenant-details-offerBtn {
  color: #ffc700;
  background: rgba(255, 199, 0, 0.1);
}

.tenant-details-offerBtn path {
  fill: #ffc700;
}
