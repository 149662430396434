.current1 {
	/* border: 1px solid magenta; */
	display: flex;
	flex-direction: column;
	gap: 4rem;
}

.current1 .current1__important {

}

.current1 .current1__important .important__title {
	font-size: var(--f-size-2);
	font-weight: var(--f-weight-1);
	margin-bottom: 1.2rem;
	display: inline-block;
}