.lease-unit-card {
  /* margin: 2rem 0; */
  border-radius: 6px;
  overflow: hidden;
	/* border: 1px solid magenta; */
	width: 100%;
}

.lease-unit-card_information {
  /* border: 1px solid red; */
  display: flex;
  background: var(--c-white-2);
}

.lease-unit-card_information_image {
  width: 11.4rem;
  /* height: 14.9rem; */
	/* border: 1px solid magenta; */
}

.lease-unit-card_information_image img {
  width: 100%;
	min-width: 11.4rem;
  height: 100%;
  object-fit: cover;
}

.lease-unit-card_information_details {
  padding: 1.2rem;
  font-family: var(--f-family-2);
  /* line-height: 2.2rem; */
	/* border: 1px solid magenta; */
	width: 100%;
}

.lease-unit-card_information_details .information_details_wrapper {
	/* border: 1px solid magenta; */
	width: 100%;
	margin-bottom: 1rem;
}

.information_details_wrapper .lease-unit-card_status {
  color: var(--c-white-3);
	font-family: var(--f-family-2);
	font-weight: var(--f-weight-2);
  display: flex;
  align-items: center;
  gap: 0.5rem;
	/* border: 1px solid magenta; */
	margin-bottom: 1.15rem;
}

.information_details_wrapper .lease-unit-card_status .status-bar {
  width: 1.6rem;
  height: .8rem;
  background: var(--c-white-3);
  border-radius: 100rem;
}




.information_details_wrapper .lease-unit-card_status--accept {
  color: var(--c-aqua);
}

.information_details_wrapper .lease-unit-card_status--accept .status-bar {
  background: var(--c-aqua);
}

.information_details_wrapper .lease-unit-card_status--acceptance {
  color: #45D2FF;
}

.information_details_wrapper .lease-unit-card_status--acceptance .status-bar {
  background: #45D2FF;
}

.information_details_wrapper .lease-unit-card_status--signature {
  color: #9D70FF;
}

.information_details_wrapper .lease-unit-card_status--signature .status-bar {
  background: #9D70FF;
}

.information_details_wrapper .lease-unit-card_status--payment {
  color: var(--c-orange);
}

.information_details_wrapper .lease-unit-card_status--payment .status-bar {
  background: var(--c-orange);
}

.information_details_wrapper .p1 {
	font-family: var(--f-family-2);
	font-weight: var(--f-weight-2);
	color: rgba(255, 255, 255, 0.6);;
	margin-bottom: .2rem;
	/* font-size: ; */
}

.information_details_wrapper .p2 {
	font-weight: var(--f-weight-1);
	font-family: var(--f-family-1);
	margin-bottom: .2rem;
	font-size: var(--f-size-3);
	margin-bottom: 1rem;
}

.information_details_wrapper .p3 span:nth-child(1) {
	font-family: var(--f-family-2);
	font-size: 1rem;
}

.information_details_wrapper .p3 span:nth-child(2) {
	font-weight: var(--f-weight-2);
	font-family: var(--f-family-2);
	font-size: var(--f-size-4);
}

.information-details-specs {
  display: flex;
	flex-direction: column;
  gap: .2rem;
	/* border: 1px solid magenta; */
}

.information-details-specs .details-specs-facilities {
  display: flex;
  align-items: center;
  gap: 1rem;
	/* border: 1px solid magenta; */
}

.information-details-specs .details-specs-facilities .facilities-item {
  display: flex;
  align-items: center;
  gap: 0.4rem;
	/* border: 1px solid magenta; */
}

.information-details-specs .details-specs-facilities .facilities-item .item__bathtub {
	/* border: 1px solid magenta; */
	width: 1.5rem;
	height: 1.5rem;
}
.information-details-specs .details-specs-facilities .facilities-item .item__bathtub svg {
	width: 100%;
	height: 100%;
}


.information-details-specs .details-specs-facilities .facilities-item p {
	font-family: var(--f-family-2);
	font-size: var(--f-size-5);
}

.information-details-specs .details-specs__sqft {
	font-family: var(--f-family-2);
	font-size: var(--f-size-5);
	/* border: 1px solid magenta; */
}

/* Lease Tenant */
.lease-unit-card__offer-summary {
	background-color: #26282E;
	padding: 1.1rem 1.5rem;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.lease-unit-card__offer-summary .offer-summary__head {
	display: flex;
	align-items: center;
	gap: .55rem;
	font-family: var(--f-family-2);
	color: var(--c-yellow);
	/* border: 1px solid magenta; */
}

.lease-unit-card__offer-summary .offer-summary__head svg path {
	fill: var(--c-yellow);
}

.lease-unit-card__offer-summary .offer-summary__accepted,
.lease-unit-card__offer-summary .offer-summary__date {
	/* border: 1px solid magenta; */
	display: flex;
	gap: 2rem;
}

.lease-unit-card__offer-summary .offer-summary__accepted div p:nth-child(1),
.lease-unit-card__offer-summary .offer-summary__date div p:nth-child(1) {
	font-size: 1rem;
	font-family: var(--f-family-2);
	margin-bottom: .2rem;
	pointer-events: none;
}

.lease-unit-card__offer-summary .offer-summary__accepted div p:nth-child(2),
.lease-unit-card__offer-summary .offer-summary__date div p:nth-child(2) {
	font-size: var(--f-size-4);
	font-family: var(--f-family-2);
}

.lease-unit-card .lease-unit-card__button {
	/* border: 1px solid magenta; */
	padding: 1.1rem 1.4rem;
	background-color: var(--c-white-2);
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
}

.lease-unit-card-button {
  display: flex;
  background: var(--c-white-2);
  padding: 1.1rem 1.4rem;
  gap: 1.2rem;
}

.lease-unit-card-button--sumleas {
	background-color: #26282E;
}

.lease-unit-card .lease-unit-card__button button {
	/* border: 1px solid magenta; */
}
.unit-currency {
	font-size: 1rem;
}
/* End of Lease Tenant */