.review-ta__container {
  /* border: 1px solid magenta; */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.offer-head,
.offer-head__back-button {
  width: 100%;
}

.offer-head {
  background-color: var(--c-navy);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 3rem 2rem 1rem;
  box-shadow: 0px 4px 10px #00000051;
  top: 0;
  position: sticky;
  /* border: 1px solid magenta; */
  z-index: 1;
}

.offer-head__back-button {
  display: flex;
  flex-direction: row;
  gap: 9px;
  align-items: center;
}

.offer-head__back-icon path {
  width: 2rem;
  height: 2rem;
}

.offer-head__back-text {
  font-size: 2rem;
  font-weight: var(--f-weight-2);
}

.offer-head__tab-switch-container {
  display: flex;
  flex-direction: row;
  /* align-items: flex-end; */
  gap: 10px;
}
.tab-text,
.tab-text__active {
  font-size: 1.4rem;
  font-weight: var(--f-weight-2);
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}
.tab-text::after,
.tab-text__active::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 4px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 99px;
}

.tab-text {
  color: rgba(255, 255, 255, 0.6);
}

.tab-text__active {
  color: var(--c-yellow);
}

.tab-text::after {
  background: rgba(255, 255, 255, 0.6);
}

.tab-text__active::after {
  background: var(--c-yellow);
}

.offer-head__tab-switch {
  width: 100px;
  /* flex: 1; */
  cursor: pointer;
}

.offer-loi {
  flex: 1;
  /* height: 100%; */
  /* background-color: beige; */
  display: flex;
  flex-direction: column;
  /* border: 1px solid magenta; */
}

.offer-body {
  /* min-height: calc(100vh - 126px); */
  flex: 1;
  height: 100px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  /* border: 1px solid magenta; */
  /* background-color: #df856286; */
}

.offer-body-ro {
  padding: 2rem;
}

.offer-body__content {
  padding: 2rem;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  overflow-y: auto;
}

.offer-body__flex-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-top: 8px;
}

.offer-body__section-container {
  padding: 1.1rem 1.4rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
}
.offer-body__title {
  font-size: 1.6rem;
  color: white;
  font-weight: var(--f-weight-1);
}
.offer-body__text {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.6);
}

.offer-body__info-icon path {
  fill: rgba(255, 255, 255, 0.6);
}

.offer-body__form-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.review-offer__form-container {
  position: relative;
  border-radius: .6rem;
  overflow: hidden;
}

.review-offer__form-container .iframe-overlay {
  background-color: transparent;
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
}
/* .review-offer__form-container iframe {
  -webkit-transform: scale(0.5, 0.5); 
  -o-transform: scale(0.5, 0.5);
  -ms-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5); 
  -moz-transform-origin: top left;
  -webkit-transform-origin: top left;
  -o-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
} */


.offer-body__form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.offer-body__form-input {
  padding: 1.2rem 1.4rem;
  background: unset;
  border: 1px solid white;
  border-radius: 6px;
  outline: none;
  color: white;
  overflow-y: visible;
  resize: none;
  font-family: var(--f-family-2);
  flex: 1;
}

.offer-body__text-area {
  width: 100%;
  resize: none;
  background-color: unset;
  border: unset;
  outline: none;
  color: white;
  margin-bottom: 20px;
}

.offer-body__text-area-counter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.offer-body__form-input::placeholder {
  color: white;
  font-weight: var(--f-weight-2);
}
.offer-body__form-input::-webkit-date-time-edit-text,
.offer-body__form-input::-webkit-datetime-edit-month-field,
.offer-body__form-input::-webkit-datetime-edit-day-field,
.offer-body__form-input::-webkit-datetime-edit-year-field {
  color: white;
}
.offer-body__form-input:disabled {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.39);
  color: rgba(255, 255, 255, 0.39);
}
.offer-body__input-label {
  font-size: 1.4rem;
  font-weight: var(--f-weight-2);
}

.offer-body__radio-btn {
  display: none;
}

.offer-body__flex-row-container .offer-radio__label {
  display: inline-block;
  font-size: 1.2rem;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 6px 10px;
  border-radius: 99px;
  color: white;
  cursor: pointer;
}

.offer-body__flex-row-container
  .offer-body__radio-btn:checked
  + .offer-radio__label {
  background-color: rgba(255, 199, 0, 0.1);
  color: var(--c-yellow);
}

.offer-body__deposit-box {
  width: 100%;
  margin-top: 2rem;
  border: 1px solid var(--c-yellow);
  border-radius: 6px;
  padding: 1.1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.offer-body__deposit-currency {
  font-size: 1.6rem;
  font-weight: var(--f-weight-2);
  color: var(--c-yellow);
}

.offer-body__paying-to {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.offer-body__deposit-amount {
  font-size: 2.4rem;
}

.offer-body__paying-to-text {
  color: rgba(255, 255, 255, 0.6);
  font-weight: var(--f-weight-2);
  text-transform: uppercase;
  line-height: 1.149rem;
  font-size: 1rem;
}

.offer-body__text-container {
  display: flex;
  gap: 4.5px;
  align-items: center;
}

.offer-body__deposit-icon path {
  fill: rgba(255, 255, 255, 0.6);
}

.offer-body__tab-container {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.offer-body__tab-selector,
.offer-body__tab-selector-active {
  padding: 0.6rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.offer-body__tab-selector {
  font-weight: var(--f-weight-2);
  color: rgba(255, 255, 255, 0.6);
}

.offer-body__tab-selector .tab-selector__icon path {
  fill: rgba(255, 255, 255, 0.6);
}

.offer-body__tab-selector-active {
  background-color: var(--c-yellow);
  color: var(--c-navy);
  border-radius: 6px;
}

.offer-body__tab-selector-active p {
  font-weight: var(--f-weight-2) !important;
}

.offer-body__tab-selector-active .tab-selector__icon path {
  fill: var(--c-navy);
}

.button-add-card {
  margin-top: 20px;
  width: 100%;
  background-color: rgba(255, 199, 0, 0.1);
  color: var(--c-yellow);
  padding: 1.2rem 0;
  display: flex;
  justify-content: center;

  border-radius: 6px;
}

.button-add-card__text {
  font-weight: var(--f-weight-2);
  font-size: 1.4rem;
}

.bank-transfer__container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.review-offer__form-container {
  height: 500px;
  background-color: black;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-offer__item {
  width: 100%;
}

.offer-body__flex-container {
  display: flex;
  gap: 20px;
}

.paynow__content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  gap: 2rem;
  width: 100%;
}

.offer-body__warning-container {
  background-color: rgba(252, 104, 45, 0.05);
  padding: 0.6rem 1rem;
  display: flex;
  gap: 0.6rem;
}

.offer-body__warning-icon path {
  fill: #fc682d;
}

.offer-body__warning-text {
  color: #fc682d;
  font-size: 1.2rem;
}

.offer-body__head {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  /* border: 1px solid magenta; */
}

.offer-body__head span {
  /* border: 1px solid magenta; */
  height: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 199, 0, 0.1);
  color: var(--c-yellow);
  font-size: var(--f-size-5);
  padding: 0 1rem;
  border-radius: 99rem;
}

/* .review-ta__container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: hidden;
  border: 1px solid red;
} */
/* 

.offer-button {
  position: unset;
} */