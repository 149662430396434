.onboarding {
	/* border: 1px solid magenta; */
	min-height: 100vh;
	position: relative;
}

.onboarding__nav {
	/* border: 1px solid magenta; */
	padding: 3rem 2rem 1rem 2rem;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
	background-color: var(--c-navy);
	position: sticky;
	top: 0;
}

.onboarding__nav .onboarding__head {
	display: flex;
	gap: 1rem;
	align-items: center;
}

.onboarding__nav .onboarding__head h1 {
	display: flex;
	gap: 1rem;
	align-items: center;
	font-size: var(--f-size-2);
	font-weight: var(--f-weight-1);
}

.onboarding__nav .onboarding__tabs {
	/* border: 1px solid magenta; */
	display: flex;
	gap: 1rem;
	margin-top: 2rem;
}

.onboarding__nav .onboarding__tabs .button__tab {
	flex: 1;
	background-color: var(--c-navy);
	color: var(--c-white-3);
	font-size: var(--f-size-5);
	font-weight: var(--f-weight-1);
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: .6rem;
	border: none;
	outline: none;
}

.onboarding__nav .onboarding__tabs .button__tab--active {
	color: var(--c-yellow);
}

.onboarding__nav .onboarding__tabs .button__tab--active .button__tab-line {
	background-color: var(--c-yellow);
}

.onboarding__nav .onboarding__tabs .button__tab--done {
	color: var(--c-white);
}

.onboarding__nav .onboarding__tabs .button__tab--done .button__tab-line {
	background-color: var(--c-white);
}

.onboarding__nav .onboarding__tabs .button__tab-line {
	width: 100%;
	height: .4rem;
	background-color: var(--c-white-3);
	border-radius: .6rem;
}


.onboarding__outlet-container {
	/* border: 1px solid magenta; */
	padding: 2rem;
	padding-bottom: calc(2rem + 6.8rem);
	height: calc(100vh - 11.2rem);
	overflow: scroll ;
}

