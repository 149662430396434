.package-card-container {
  background: var(--c-white-2);
  padding: 1.4rem;
  border-radius: 6px;
  margin-bottom: 1.6rem;
  position: relative;
  cursor: pointer;
}

.new-property-nav {
  z-index: 1;
}

.package-card-illustration {
  display: flex;
  justify-content: center;
  margin-bottom: 2.5rem;
}

.package-card-details__title {
  font-size: var(--f-size-3);
  font-weight: var(--f-weight-1);
}

.card-title-selected {
  color: var(--c-yellow);
}

.packagae-card-label {
  padding: 0.8rem 1rem;
  color: var(--c-yellow);
  font-size: var(--f-size-5);
  border-radius: 99px;
  background: rgba(255, 199, 0, 0.1);
  width: fit-content;
  position: absolute;
  top: 2rem;
  left: 1.4rem;
}

.package-card-features-container_title {
  color: var(--c-yellow);
  margin-bottom: 1rem;
  font-weight: var(--f-weight-2);
}

.package-card-details {
  display: flex;
  justify-content: space-between;
}

.package-card-details_desc {
  line-height: 1.4rem;
  width: 15.2rem;
  color: rgba(255, 255, 255, 0.6);
  font-family: var(--f-family-2);
}

.package-card-details_advantage {
  font-size: 3rem;
  text-align: right;
  font-family: var(--f-family-2);
  color: var(--c-yellow);
  line-height: 2.5rem;
}

.package-card-features-container {
  margin-top: 2.3rem;
}

.package-card-features_item {
  font-family: var(--f-family-2);
  display: flex;
  align-items: center;
  gap: 1.2rem;
  font-size: var(--f-size-4);
  font-weight: var(--f-weight-3);
}

.package-card-button {
  margin-top: 2.5rem;
}

.details-form-container {
  background: var(--c-white-2);
  padding: 1rem 1.4rem;
  border-radius: 6px;
  margin-bottom: 2rem;
}

.details-form-container--addrequest {
  padding: 0;
  background-color: transparent;
}

.type-option-container {
  margin-top: 2rem;
}

.type-option_heading {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.type-option_item-container {
  display: flex;
  gap: 1.2rem;
  margin-top: 1rem;
}

.type-option_item-container.item-container--column {
  flex-direction: column;
  gap: 1.6rem;
}

.type-option_item {
  background: var(--c-white-2);
  color: rgba(255, 255, 255, 0.6);
  padding: 0.6rem 1rem;
  border-radius: 99px;
  font-family: var(--f-family-2);
  cursor: pointer;
}

.type-option__extended {
  margin-top: 1.2rem;
}

.option-selected {
  background-color: rgba(255, 199, 0, 0.1);
  color: var(--c-yellow);
}

.form-input-row {
  display: flex;
  gap: 1rem;
}

.form-input_radio-container {
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  align-items: center;
  margin-top: 1rem;
}

.form-input_radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.form-availability-container {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.form-input__currency,
.form-input__floor-area {
  position: relative;
  font-size: 1.2rem;
}

.form-input__currency input.input__text {
  background-color: transparent;
  border: 1px solid var(--c-white);
  padding: 1.2rem 1.4rem 1.2rem 3rem;
  border-radius: 0.6rem;
  width: 100%;
  margin-top: 1rem;
  font-family: var(--f-family-1);
  color: var(--c-white);
  font-size: var(--f-size-4);
  outline: none;
}

.form-input__floor-area input.input__text {
  background-color: transparent;
  border: 1px solid var(--c-white);
  padding: 1.2rem;
  border-radius: 0.6rem;
  margin-top: 1rem;
  font-family: var(--f-family-1);
  color: var(--c-white);
  font-size: var(--f-size-4);
  outline: none;
}

.form-input__currency-label {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 50%;
  padding-left: 1rem;
  font-weight: var(--f-weight-2);
}

.form-input__floor-area-label {
  position: absolute;
  right: 1rem;
  bottom: 0;
  top: 50%;
  padding-left: 1rem;
  font-weight: var(--f-weight-2);
}

.floor-area-box {
  background-color: transparent;
  border: 1px solid var(--c-white);
  padding: 1.2rem;
  border-radius: 0.6rem;
  margin-top: 1rem;
  font-family: var(--f-family-1);
  color: var(--c-white);
  font-size: var(--f-size-4);
  outline: none;
  opacity: 0.5;
}

.new-property-details-title {
  font-size: var(--f-size-2);
  font-weight: var(--f-weight-1);
  margin-bottom: 1rem;
}

.radio-input_item {
  display: flex;
  align-items: center;
  font-size: var(--f-size-6);
  gap: 0.5rem;
}

.date-picker-container {
  background-color: transparent;
  border: 1px solid var(--c-white);
  padding: 1.2rem 1.4rem;
  border-radius: 0.6rem;
  width: 100%;
  font-family: var(--f-family-1);
  color: var(--c-white);
  font-size: var(--f-size-4);
  outline: none;
  cursor: pointer;
}

.form-amenities_title {
  font-size: var(--f-size-4);
  color: var(--c-yellow);
  margin-bottom: 1rem;
  font-weight: var(--f-weight-1);
}

.docs-type-separator {
  margin: 1rem 0;
  text-align: center;
}

.preview-item-card-container {
  margin: 1.6rem 0;
}

.preview-total-amount_card {
  border: 1px solid var(--c-yellow);
  margin-top: 1rem;
  border-radius: 0.6rem;
  text-align: center;
  padding: 0.8rem;
  font-family: var(--f-family-2);
  line-height: 2rem;
}

.preview-total-amount_type {
  font-size: var(--f-size-5);
  color: rgba(255, 255, 255, 0.6);
  font-weight: var(--f-weight-1);
}

.preview-total-amount_value {
  font-size: var(--f-size-5);
  color: var(--c-yellow);
  font-weight: var(--f-weight-1);
}
