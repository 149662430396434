.accordion-item {
  margin-bottom: 1.2rem;
}

.accordion-title {
  display: flex;
	align-items: center;
	justify-content: space-between;
  cursor: pointer;
  background-color: var(--c-white-2);
	/* border-radius: .6rem .6rem 0 0; */
	border-radius: .6rem;
  padding: 1.2rem 1.4rem;
}

.accordion-item--active .accordion-title {
	border-radius: .6rem .6rem 0 0;
}

.accordion-item--active1 .accordion-title {
  background-color: var(--c-white-2);
	border-radius: .6rem .6rem 0 0;
}

.accordion-item--handover .accordion-title .accordion-title--wrap {
	padding-right: 2rem;
}

.accordion-item--handover .button__style-5 {
	justify-content: flex-start;
	padding: 1.2rem 1.6rem;
}

/* .accordion-title:hover {
  background-color: var(--c-white-3);
} */

.accordion-title,
.accordion-content {
  padding: 1rem;
}

.accordion-content {
  background-color: var(--c-white-2);
  padding: 1rem 1.4rem;
	border-radius: 0 0 .6rem .6rem;
}


.accordion-item--active .accordion-title {
  background-color: rgba(255, 199, 0, 0.1);
	color: var(--c-yellow);
}

.accordion-item--active .accordion-content {
  background-color: rgba(255, 199, 0, 0.1);
	color: var(--c-yellow);
}

.accordion-item--active svg.svg__down path {
	fill: var(--c-yellow);
}

.accordion-item--active svg.svg__up path {
	fill: var(--c-yellow);
}

.accordion-content {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.accordion-content-nogap {
	gap: unset;
}

.accordion-content .content__title {
	font-size: var(--f-size-4);
	font-weight: var(--f-weight-2);
	font-family: var(--f-family-2);
	margin-bottom: 1rem;
	display: inline-block;
}

.accordion-content .content__list {
	font-size: var(--f-size-4);
	color: var(--c-white-1);
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding-left: 2rem;
}

.accordion-content .content__list li {
	color: var(--c-white);
	font-family: var(--f-family-2);
	font-weight: var(--f-weight-3);
}

/* .content__wrapper-precon { */
	/* border: 1px solid magenta; */
/* } */

.content__wrapper-precon .precon-images {
	margin-right: 3.6rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;
}

.content__wrapper-precon .precon-image {
	/* border: 1px solid magenta; */
	border-radius: .3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--c-yellow);
	overflow: hidden;
	aspect-ratio: 1 / 1;
}

.content__wrapper-precon .precon-image img {
	aspect-ratio: 1 / 1;
}

/* .accordion-content .addInv { */
	/* border: 1px solid magenta; */
/* } */

.accordion-content .addInv .addInv-check {
	/* border: 1px solid magenta; */
	margin-bottom: 1.2rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
} 

.accordion-content .addInv .addInv-check .form-input {
	margin: 0;
} 

.accordion-content .addInv .addInv-check label {
	font-size: var(--f-size-4);
} 

.accordion-content .addInv .addInv-actions {
	/* border: 1px solid magenta; */
	display: flex;
	gap: 2rem;
	justify-content: flex-start;
	margin-bottom: 1rem;
}

.accordion-content .addInv .addInv-actions button {
	width: max-content;
}

/* .accordion-content .addInv .addInv-verif { */
	/* border: 1px solid magenta; */
/* } */

.title-up-precon {
	color: var(--c-white);
	font-size: var(--f-size-5);
	margin-bottom: 1.2rem;
	line-height: 1.6rem;
}