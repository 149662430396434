.head-back {
	/* border: 1px solid magenta; */
	display: flex;
	align-items: center;
	gap: 1.5rem;
	padding: 3rem 2rem 1rem 2rem;
	background-color: var(--c-navy);
	/* z-index: 20; */
	position: relative;
	/* margin-bottom: 2rem; */
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

.head-back h1 {
	font-size: var(--f-size-2);
	font-weight: var(--f-weight-1);
}
