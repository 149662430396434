.agreement-page {
  position: relative;
  min-height: 100vh;
}

.agreement-point-container {
  background: #1c263d;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  display: flex;
  gap: 1.2rem;
  padding: 1rem 2rem;
  width: 100%;
  overflow: scroll;
}

.agreement-point_item-container {
  background: var(--c-white-2);
  padding: 1rem 1.2rem;
  border-radius: 99px;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: var(--f-family-2);
}

.point-active {
  background: rgba(255, 199, 0, 0.1);
  color: var(--c-yellow);
}

.agreement-point_item-container .point-indicator {
  position: absolute;
  right: 0;
  top: 0;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 99px;
}

.agreement-point_item-container .point-indicator.accept {
  background-color: var(--c-aqua);
}

.agreement-point_item-container .point-indicator.edit {
  background-color: var(--c-red);
}

.agreement-content {
  padding: 2rem 2rem 10rem 2rem;
}

.agreement-item_box {
  background: var(--c-white-2);
  padding: 1.1rem 1.4rem;
  margin: 2rem 0;
  border-radius: 0.6rem;
}

.agreement-item-edits_box {
  background: rgba(252, 104, 45, 0.05);
  padding: 1.1rem 1.4rem;
  margin: 2rem 0;
  border-radius: 0.6rem;
}

.agreement-item_heading {
  font-size: var(--f-size-3);
  font-weight: var(--f-weight-1);
  color: var(--c-yellow);
  margin-bottom: 1rem;
}

.agreement-item-edits_heading {
  font-size: var(--f-size-3);
  font-weight: var(--f-weight-1);
  color: var(--c-red);
  margin-bottom: 1rem;
}

.agreement-item_content {
  display: flex;
  font-size: var(--f-size-4);
  font-weight: var(--f-weight-1);
  justify-content: space-between;
  margin-bottom: 1rem;
}

.agreement-item_desc {
  font-size: var(--f-size-4);
  font-family: var(--f-family-2);
  font-weight: var(--f-weight-2);
}

.agreement-item_highlight {
  color: var(--c-yellow);
}

.agreement-button_container {
  background: var(--c-black-1);
  padding: 1.5rem 2rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  gap: 1rem;
}
