.loi {
	/* color: red; */
	font-size: 1.2rem;
	font-weight: 500;
	color: black;
	background-color: white;
	padding: 5rem;
	/* width: 2rem; */
	/* height: 1920px; */
	/* height: 100vh; */
	/* width: 100%; */
}


.loi a {
	color: black;
	text-decoration: none;
}

.loi-head {
	/* border: 1px solid magenta; */
}

.loi-head img {
	width: 100%;
	max-width: 7rem;
}

.loi-head p {
	border-bottom: 2px solid black;
	padding: 1rem 0;
	padding-top: 0;
}

.loi-head .loi-title p {
	padding: 0;
	margin-top: 1rem;
	border: none;
	text-align: center;
	font-size: 1.6rem;
	font-weight: 700;
}

/* content 1 */
.loi-content .content {
	/* border: 1px solid magenta; */
	margin: 2rem 0;
	display: flex;
	flex-direction: column;
	gap: .5rem;
}

.loi-content .content p {
	/* border: 1px solid magenta; */
	padding: .5rem 0;
}

.loi-content .content__grid {
	display: grid;
	grid-template-areas:
	"title1 title2 dot value";
	grid-template-columns: 15rem 10rem max-content 1fr;
	grid-template-rows: auto;

	/* grid-template-columns: 15rem 10rem max-content 1fr; */
	/* border: 1px solid magenta; */
}



.loi-content .content__grid .dot,
.loi-content .content__grid .num {
	padding: .5rem 1rem;
}

.loi-content .content__grid .value {
	border-bottom: 1px solid black;
	/* text-decoration: underline; */
	padding: .5rem 0;
	min-height: 2.4rem;
	}

.loi-content .content__grid-sub {
	/* border: 1px solid magenta; */
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.loi-content .content__grid-sub .singapore {
	display: flex;
}

.loi-content .content__grid-sub .singapore .value {
	flex: 1;
}
/* end content 1 */

/* content 2 */
.loi-content .content__grid.col2 {
	display: grid;
	grid-template-columns: max-content 1fr;
	gap: 1rem;
	font-weight: 700;
}

.loi-content .content__grid.col2-bas {
	display: grid;
	grid-template-columns: max-content 1fr;
}


.loi-content .content__grid .value.bold {
	border-bottom: 2px solid black;
	/* text-decoration: underline; */
	padding: .5rem 0;
	min-height: 2.4rem;
}
/* end content 2 */

/* content 3 */
.loi-content .content__grid.col3 {
	display: grid;
	grid-template-columns: 20rem max-content 1fr;
}

.loi-content .content-desktop {
	display: grid;
}

.loi-content .content-mobile {
	display: none;
	flex-direction: column;
	gap: 1rem;
}

.loi-content .content-mobile .content__grid,
.loi-content .content-mobile .content__grid.col2,
.loi-content .content-mobile .content__grid.col3,
.loi-content .content-mobile .content__grid.col4 {
	display: flex;
	flex-direction: column;
}

.loi-content .content-mobile p.num {
	padding: .5rem 0;
}

/* end content 3 */

/* content 4 */
.loi-content .content__grid.col4 {
	display: grid;
	grid-template-columns: max-content 17rem max-content 1fr;
}

.loi-content .content__grid .value-parent {
	padding: 0;
	border: none;
}

.loi-content .content__grid .value-nob {
	border: none;
}

.loi-content .content__grid .value span {
	padding: .5rem 0;
}

.loi-content .content__grid .value .value-sub-1 {
	border-bottom: 1px solid black;
	/* text-decoration: underline; */
	min-width: 10rem;
	margin: 0 .5rem;
	display: inline-block;
}

.loi-content .content__grid .value .value-sub-1-nom {
	margin-left: 0;
}

.loi-content .content__grid .value .value-sub-2 {
	display: inline-block;
	margin-right: .5rem;
}
/* end content 4 */

/* content 5 */
.loi-content .content-sub {
	padding: 0 2rem;
	margin-top: 0;
}

.loi-content .content-nomb {
	margin-bottom: 1rem;
}

.loi-content .content-sub .content__grid {
	gap: .5rem;
}
/* end content 5 */


/* content 6 */
.loi-content .content__grid.no-col {
	display: initial;
}

.loi-content .content__grid.col3-1 {
	grid-template-columns: max-content max-content 1fr;
}

.loi-content .content__grid.col3-sig {
	grid-template-columns: max-content 1fr max-content;
	gap: 1rem;
}

.loi-content .content__grid-sub-no-gap {
	gap: unset;
}
/* content 6 */

/* content 7 */
.loi-content .content__grid.col-set-2 {
	display: grid;
	grid-template-columns: 10rem 1fr;
}
.loi-content .content__grid.col-set-3 {
	grid-template-columns: 10rem max-content 1fr;
}
.loi-content .content__grid.col-set-3-1 {
	grid-template-columns: 10rem 1fr max-content;
	gap: 1rem;
}

.loi-content .content-wrapper {
	display: flex;
	gap: 5rem;
}

.loi-content .content-wrapper .content {
	flex: 1;
}

.loi-content .content-footer {
	margin-top: 8rem;
}
.loi-content .content-box__wrapper {
	padding: 2.5rem 0;
	padding-bottom: 0;
}

.loi-content .content-box {
	border: 1px solid black;
	padding: 1rem 1.5rem;
	font-weight: 500;
}

.loi-content .content-box-nb {
	border: none;
	font-weight: 400;
}

.loi-content .content-box p {
	padding: 0;
	font-style: italic;
}

.loi-content .signature {
	border-bottom: 1px solid black;
	min-height: 2.4rem;
}

.loi-content .signature img {
	width: 100%;
	max-width: 7rem;
}
/* edn content 7 */

@media only screen and (max-width: 580px) {
  .loi {
		padding: 2rem;
	}

	.loi-content .content-desktop {
		display: none;
	}

	.loi-content .content-mobile {
		display: flex;
	}
}

/* @media only screen and (min-width: 580px) {
	.loi-content .content-desktop {
		display: none;
	}

	.loi-content .content-mobile {
		display: flex;
	}
} */

@media only screen and (max-width: 720px) {
	.loi-content .content-wrapper {
		flex-direction: column;
	}

	/* .loi-content .content-mobile {
		display: flex;
	} */
}