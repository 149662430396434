.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  /* width: 100%; */
  /* max-width: 36rem; */
  /* border: 1px solid magenta; */
  background-color: black;
  padding: 1.2rem 1.4rem;
  z-index: 99;
  /* display: none; */
}

@media only screen and (min-width: 580px) {
  .footer {
    display: none;
  }
}

.footer .menu {
  /* border: 1px solid magenta; */
  display: flex;
}

.footer .menu .menu__link {
  /* border: 1px solid blue; */
  flex: 1;
  color: var(--c-white);
}

.footer .menu .menu__link.active .menu__item {
  background: rgba(255, 199, 0, 0.1);
  color: var(--c-yellow);
}

.footer .menu .menu__link.active .menu__item .menu__item-svg path {
  fill: var(--c-yellow);
  fill-opacity: 1;
}

.footer .menu .menu__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 100%;
  padding: 0.8rem 0 0.6rem 0;
  border-radius: 0.6rem;
  /* border: 1px solid magenta; */
}

.footer .menu .menu__item p {
  font-size: var(--f-size-5);
}

/* .footer .menu .menu__item-svg {
} */

/* .footer .menu .menu__item-svg path { */
/* border: 1px solid magenta; */
/* fill: red; */
/* fill-opacity: 1; */
/* } */
