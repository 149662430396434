.current__manage .p1 {
	/* border: 1px solid magenta; */
	font-size: var(--f-size-1);
	font-weight: var(--f-weight-1);
	margin-bottom: 1.4rem;
}

.current__manage .manage__box {
	/* border: 1px solid magenta; */
	padding: 1.2rem 1.4rem;
	background-color: var(--c-white-2);
	border-radius: .6rem;
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
}

.current__manage .manage__box .box__row {
	display: flex;
	gap: 1.6rem 2rem;
	flex-wrap: wrap;
}

.current__manage .manage__box .box__row .unit {
	font-size: var(--f-size-6);
}

.current__manage .manage__box .box__row:nth-child(2) {
	gap: 1.6rem;
}

.current__manage .manage__box .box__row div {
	/* border: 1px solid magenta; */
	display: flex;
	flex-direction: column;
	gap: .2rem;
	font-family: var(--f-family-2);
}

.current__manage .manage__box .box__row div p:nth-child(1) {
	/* border: 1px solid magenta; */
}
.current__manage .manage__box .box__row div p:nth-child(2) {
	/* border: 1px solid magenta; */
	font-size: var(--f-size-4);
}

/* box row 2 */
.box__row-2 {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	/* border: 1px solid magenta; */
	gap: 2rem;
	/* width: 100%; */
	/* min-width: 0; */
}


/* .box__row-2 .row-2__illus { */
	/* border: 1px solid magenta; */
/* } */

.box__row-2 .row-2__desc {
	/* border: 1px solid magenta; */
	min-width: 0;
}

.box__row-2 .row-2__desc span {
	font-size: var(--f-size-3);
	font-weight: var(--f-weight-1);
	display: inline-block;
	/* border: 1px solid magenta; */
	width: 100%;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
	margin-bottom: 1rem;
}

.box__row-2 .row-2__desc p {
	font-size: var(--f-size-5);
	line-height: 1.6rem;
}

.box__row--btn-handover,
.box__row--btn-handover-1 {
	gap: 1rem !important;
}

.box__row--btn-handover a,
.box__row--btn-handover-1 a {
	/* border: 1px solid magenta !important; */
	width: 100%;
	padding: 0 !important;
}

.box__row--btn-handover a div {
	max-width: unset !important;
}

.box__row--btn-handover-1 a div {
	max-width: unset !important;
	/* align-items: flex-start !important; */
	/* background-color: rgba(11, 229, 203, 0.05) !important; */
	/* color: var(--c-aqua); */
}