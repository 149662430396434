.leased {
	/* border: 2px solid white; */
	flex: 1;
	height: 100px;
	display: flex;
	flex-direction: column;
	/* position: relative; */
	/* z-index: -1; */
}

.leased-body {
	flex: 1;
	/* border: 1px solid magenta; */
	overflow-y: auto;
	/* overflow: hidden; */
	margin-bottom: 7.1rem;
}

.leased-wrapper {
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
}