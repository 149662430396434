.button__box {
  /* border: 1px solid magenta; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--f-size-3);
  font-weight: var(--f-weight-1);
  color: var(--c-yellow);
  background: rgba(255, 199, 0, 0.1);
  border-radius: 0.6rem;
  padding: 1.55rem 0;
  width: 100%;
  max-width: 28rem;
  cursor: pointer;
}

.button__link--disabled .button__box {
  background-color: var(--c-white-2);
  color: rgba(255, 255, 255, 0.39);
}

.button__link--disabled {
  pointer-events: none;
}

.button__box--type2 {
  background: var(--c-white-2);
  color: var(--c-white);
  padding: 1.2rem 1.4rem;
  font-size: var(--f-size-4);
}

.button__box--type2.orange {
  background: rgba(255, 199, 0, 0.1);
  color: var(--c-yellow);
}

.button__box--type2 p span {
  margin-right: 0.867rem;
}

.button__box--type2 p span svg path {
  fill: var(--c-aqua);
}
