.handoc {
	/* border: 1px solid white; */
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.handoc-body {
	/* border: 1px solid magenta; */
	flex: 1;
	overflow-y: auto;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.handoc-title {
	font-weight: var(--f-weight-1);
	font-size: var(--f-size-2);
}