.lease__t {
	/* border: 1px solid magenta; */
	height: 100%;
	position: relative;
}

.lease__t-body {
	/* border: 1px solid magenta; */
	height: calc(100vh - (13.5rem + 7.1rem));
	overflow-y: scroll;
	z-index: -1;
}

.lease__t-body--current {
	height: calc(100vh - (13.5rem + 7.1rem));
	padding: 2rem;
}

.lease__t-body--new {
	height: calc(100vh - (12.2rem + 7.1rem));
}