.listing-guide {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.guide-top-bar {
  background: #1f2944;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  display: flex;
  gap: 1.5rem;
  padding-top: 3rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  padding-left: 2.3rem;
  font-weight: var(--f-weight-1);
  color: var(--c-white);
  align-items: center;
}

.guide-top-bar-icon {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.guide-top-bar-icon svg {
  width: 100%;
  height: 100%;
}

.lease-content {
  flex: 1;
  overflow-y: auto;
}

.guide-information-box {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  gap: 1rem;
}

.guide-information-box_icon svg path {
  fill: rgba(255, 255, 255, 0.6);
}

.guide-information-box_desc {
  color: rgba(255, 255, 255, 0.6);
  font-size: var(--f-size-5);
}

.accordion-guide {
  max-width: 600px;
  margin: 2rem auto;
}
