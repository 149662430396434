.splide {
  padding: 0 !important;
}

.splide__pagination {
  /* border: 1px solid magenta; */
  bottom: calc(-3rem + -1.3rem) !important;
  gap: 1rem;
}
.splide__pagination__page {
  /* width: 1rem !important; */
  /* height: 1rem !important; */
  outline: none !important;
  /* transition: all .3s ease; */
  border-radius: 10rem !important;
  background-color: var(--c-white) !important;
  opacity: 1 !important;
  /* margin: 0 !important; */
}

.splide__pagination__page.is-active {
  background-color: var(--c-yellow) !important;
  opacity: 1;
  /* width: 2.5rem !important;
	height: 1rem !important;
	transition: all .3s ease;
	outline: none !important; */
}

/* .splide__pagination__page:hover {
	opacity: 1;
	background-color: white !important;
} */
