.whats-nearby {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 1.2rem;
}

.whats-nearby__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.1rem 1.4rem;
    gap: 1.2rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 0.6rem;
  }

.whats-nearby__list__active {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.1rem 1.4rem;
  gap: 1.2rem;
  background: rgba(255, 199, 0, 0.1);
  border-radius: 0.6rem;
}

.whats-nearby__list-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: relative;
    gap: 0.6rem;
    font-weight: 700;
  }

.whats-nearby__list-title__active {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
  gap: 0.6rem;
  color: var(--c-yellow);
  font-weight: 700;
}

.whats-nearby__list-title__active path {
  fill: var(--c-yellow);
}

.whats-nearby__list-title-chevron {
  margin-left: auto;
}

.whats-nearby__list-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;
  width: 100%;
  position: relative;
}

.whats-nearby__list-content .title {
  color: var(--c-yellow);
}

.whats-nearby__list-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.6rem;
}

.list-content-station,
.list-content-bus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  padding-right: 3.5rem;
}

.list-content-station-flag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  padding: 0.2rem 0.6rem 0.2rem 0.2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 9.9rem;
  font-family: var(--f-family-2);
}

.list-content-station-flag div {
  padding: 0.2rem 0.5rem;
  border-radius: 10rem;
  background-color: var(--c-red);
  font-size: 0.8rem;
}

.list-content-station-flag div.CCL{
    background-color: var(--c-yellow);
}

.list-content-station-estimation,
.list-content-bus-estimation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.8rem;
  margin-left: auto;
}

.list-content-station-estimation-distance,
.list-content-bus-estimation-distance {
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
}

.list-content-station-estimation-duration,
.list-content-bus-estimation-duration {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  opacity: 60%;
}

.list-content-station-estimation-duration svg,
.list-content-bus-estimation-duration svg {
  width: 1rem;
  height: 1rem;
}

.list-content-bus-stop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.4rem 0.6rem;
  gap: 0.2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.6rem;
}

.list-content-bus-stop-flag{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .2rem;
}

.list-content-bus-stop-flag div{
    display: flex;
    flex-direction: row;
    padding: .2rem .5rem;
    background: #5B0265;
    border-radius: 9.9rem;
}

.list-content-bus-stop-flag div.flag-red{
    background: var(--c-red);
}