.splash {
  /* border: 1px solid blue; */
  min-height: 100vh;
  padding: 4rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.splash__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #1f2944 0%, #080b12 100%) no-repeat;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  /* opacity: 1; */
  animation: splashOverlayFade 0.3s 2s ease forwards;
}

@keyframes splashOverlayFade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}

.splash__logo {
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.splash__slide {
  /* border: 1px solid blue; */
  /* background-color: white; */
  padding-bottom: 4.3rem;
  /* margin-bottom: 4.3rem; */
}

.splash__slide-card {
  display: flex;
  flex-direction: column;
  gap: clamp(0rem, 3vh, 5rem);
}

.splash__slide .card__illus {
  overflow: hidden;
  width: 100%;
  /* border: 1px solid magenta; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30rem;
}

.splash__slide .card__illus-wrapper {
  overflow: hidden;
  width: 100%;
  /* border: 1px solid magenta; */
}

.splash__slide .card__illus svg {
  width: 100%;
}

.splash__slide .card__illus-1 svg {
  /* border: 1px solid magenta; */
  width: 114%;
}

.splash__slide .card__illus-2 svg {
  /* border: 1px solid magenta; */
  width: 118%;
  transform: translateX(-2.5rem);
}

.splash__slide .card__illus-3 svg {
  /* border: 1px solid magenta; */
  width: 118%;
  transform: translateX(-2.5rem);
}

.splash__slide .card__description {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.splash__slide .card__description h1 {
  font-size: var(--f-size-1);
  font-weight: var(--f-weight-1);
}

.splash__slide .card__description p {
  font-size: var(--f-size-4);
  font-weight: var(--f-weight-3);
  font-family: var(--f-family-2);
  line-height: 1.8rem;
}

.splash__button {
  /* border: 1px solid magenta; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
}