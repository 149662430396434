.monthlychart {
	/* border: 1px solid magenta; */
	display: flex;
	flex-direction: column;
	gap: 1.4rem;
}

.monthlychart .monthlychart__title {
	font-size: var(--f-size-2);
	font-weight: var(--f-weight-1);
}

.monthlychart__overview {
	/* border: 1px solid magenta; */
	display: flex;
	justify-content: flex-start;
}

.monthlychart__overview .overview__wrapper {
	background-color: rgba(255, 199, 0, 0.1);
	padding: 1.2rem 1.4rem;
	border-radius: .6rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.monthlychart__overview .overview__title {
	font-size: var(--f-size-6);
	font-weight: var(--f-weight-2);
	font-family: var(--f-family-2);
	color: var(--c-yellow);
}

.monthlychart__overview .overview__detail{
	display: flex;
	flex-direction: column;
	gap: .5rem;
}

.monthlychart__overview .overview__detail .detail__row {
	/* border: 1px solid magenta; */
	display: flex;
	align-items: center;
	gap: 1.6rem;
}

.monthlychart__overview .overview__detail .detail__row:last-child {
	color: var(--c-yellow);
}

.monthlychart__overview .overview__detail .detail__row p:nth-child(1) {
	/* border: 1px solid magenta; */
	width: 100%;
	min-width: 10rem;
	font-size: var(--f-size-6);
	font-weight: var(--f-weight-2);
	font-family: var(--f-family-2);
}

.monthlychart__overview .overview__detail .detail__row p:nth-child(2) {
	/* border: 1px solid magenta; */
	width: 100%;
	min-width: 6rem;
	font-size: var(--f-size-5);
	font-weight: var(--f-weight-2);
	font-family: var(--f-family-2);
}

.monthlychart__overview .overview__detail .detail__row p:nth-child(2) span {
	font-size: .8rem;
}