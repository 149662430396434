/* Global Style for input */
.form-input label {
  display: flex;
  flex-direction: column;
  /* border: 1px solid magenta; */
}

.form-input input.input__text {
  background-color: transparent;
  border: 1px solid var(--c-white);
  padding: 1.2rem 1.4rem;
  border-radius: 0.6rem;
  width: 100%;
  margin-top: 1rem;
  font-family: var(--f-family-1);
  color: var(--c-white);
  font-size: var(--f-size-4);
  outline: none;
}

.form-input input.input__text::placeholder {
  color: var(--c-white-3);
}

.form-input .form-input__number {
  display: flex;
}

.form-input .form-input__number input.input__text {
  border-radius: 0 0.6rem 0.6rem 0;
}

.form-input__number select {
  background-color: transparent;
  border: 1px solid var(--c-white);
  border-right: 0;
  padding: 1.1rem 1.4rem;
  border-radius: 0.6rem 0 0 0.6rem;
  margin-top: 1rem;
  font-family: var(--f-family-2);
  color: var(--c-white);
  font-size: var(--f-size-4);
  /* border: 1px solid magenta; */
  outline: none;
  cursor: pointer;
}

.form-input__number select option {
  color: black;
}

.form-input__number input.input__text {
  width: initial;
  flex: 1;
}

.form-input .input__select {
  background-color: transparent;
  border: 1px solid var(--c-white);
  /* border-right: 0; */
  padding: 1.1rem 1.4rem;
  border-radius: 0.6rem;
  margin-top: 1rem;
  font-family: var(--f-family-2);
  color: var(--c-white);
  font-size: var(--f-size-4);
  outline: none;
}

.form-input .input__select option {
  color: black;
}

/* -- */
.addrequest-input label {
  font-size: var(--f-size-4);
  font-weight: var(--f-weight-1);
}
.form-input__select select {
  background-color: transparent;
  border: 1px solid var(--c-white);
  padding: 1.1rem 1.4rem;
  border-radius: 0.6rem;
  margin-top: 1rem;
  font-family: var(--f-family-2);
  color: var(--c-white);
  font-size: var(--f-size-4);
  outline: none;
}

.form-input__select select option {
  color: var(--c-black-1);
}

.form-input__textarea textarea {
  background-color: transparent;
  border: 1px solid var(--c-white);
  padding: 1.1rem 1.4rem;
  border-radius: 0.6rem;
  margin-top: 1rem;
  font-family: var(--f-family-2);
  color: var(--c-white);
  font-size: var(--f-size-4);
  outline: none;
}

.form-input__textarea textarea::placeholder {
  color: var(--c-white-3);
  font-size: var(--f-size-4);
  font-family: var(--f-family-2);
}

/* .form-input__radio { */
/* border: 1px solid magenta; */
/* } */

.form-input__radio .radio__wrapper,
.form-input__radio-date .radio__wrapper {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.form-input__radio label,
.form-input__radio-date label {
  /* border: 1px solid magenta; */
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

/* .form-input__radio-date { */
/* border: 1px solid magenta; */
/* } */

.form-input__radio p,
.form-input__radio-date p {
  font-size: var(--f-size-4);
  font-weight: var(--f-weight-1);
  margin-bottom: 0.8rem;
}

/* float icon input */
.form-input__input-text-icon-float {
  position: relative;
  margin-top: 1rem;
}

.form-input--bank label {
  font-size: var(--f-size-4);
}
.form-input__input-text-icon-float input.input__text {
  margin-top: 0;
}
.form-input__float-icon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  /* border: 1px solid magenta; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

/* ============Custom Radio========== */
.label__input {
  /* border: 1px solid magenta; */
  display: block;
  height: 1.6rem;
  position: relative;
  padding-left: 1.9rem;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* background: gray; */
  display: flex;
  font-size: var(--f-size-5);
}

/* Hide the browser's default radio button */
.label__input input[type='radio'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.4rem;
  width: 1.4rem;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid white;
}

.radio__wrapper input[type='radio']:checked ~ .label__text {
  color: #ffc700;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.label__input input[type='radio']:checked ~ .checkmark:after {
  display: block;
  border: 1px solid #f99d25;
}

.label__input input[type='radio']:checked ~ .checkmark {
  border: 1px solid #f99d25;
}

/* Style the indicator (dot/circle) */
.label__input .checkmark:after {
  top: 0.2rem;
  left: 0.2rem;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background: #f99d25;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

/* End of Global Style for input */

/* Global style for button */
button,
input[type='submit'] {
  cursor: pointer;
}

.button__style-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--f-size-3);
  font-weight: var(--f-weight-1);
  color: rgba(255, 255, 255, 0.39);
  background: var(--c-white-2);
  border-radius: 0.6rem;
  padding: 1.55rem 0;
  width: 100%;
  max-width: 28rem;
  outline: none;
  border: none;
  pointer-events: none;
}

.button__style-3 {
  width: 100%;
  padding: 1.2rem 0;
  border-radius: 0.6rem;
  border: none;
  outline: none;
  background-color: var(--c-white-2);
  color: var(--c-white-3);
  font-size: var(--f-size-5);
  font-weight: var(--f-weight-1);
  font-family: var(--f-family-1);
  pointer-events: none;
}

.button__style-4--1 {
  width: 100%;
  padding: 1.4rem 0;
  border-radius: 0.6rem;
  border: none;
  outline: none;
  background-color: rgba(255, 199, 0, 0.1);
  color: var(--c-yellow);
  font-size: var(--f-size-5);
  font-weight: var(--f-weight-1);
  font-family: var(--f-family-1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.button__style-4--1 svg path {
  fill: var(--c-yellow);
}

.button__style-4--2 {
  width: 100%;
  padding: 1.4rem 0;
  border-radius: 0.6rem;
  border: none;
  outline: none;
  background-color: var(--c-white-2);
  color: var(--c-white);
  font-size: var(--f-size-5);
  font-weight: var(--f-weight-1);
  font-family: var(--f-family-1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.button__style-4--2 svg path {
  fill: var(--c-white);
}

.content__handler--sign .button__style-4--1,
.content__handler--sign .button__style-4--2 {
  width: max-content;
  padding: 1.4rem;
}

.content__handler--sign .button__style-4--2 {
  pointer-events: none;
  color: var(--c-white-2);
}

.content__handler--sign .button__style-4--2-active {
  pointer-events: initial;
  color: var(--c-white-1);
}

.button__style-5 {
  width: 100%;
  padding: 1.2rem;
  border-radius: 0.6rem;
  border: none;
  outline: none;
  background-color: var(--c-white-2);
  color: var(--c-white-2);
  font-size: var(--f-size-4);
  font-weight: var(--f-weight-1);
  font-family: var(--f-family-1);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.button__style-5--normal {
  pointer-events: initial !important;
  color: white !important;
}

.button__style-5-disabled {
  pointer-events: none;
  color: var(--c-white-2);
}

.button__style-5 svg path {
  fill: var(--c-white-2);
}

.button__style-5--normal svg path {
  fill: var(--c-white) !important;
}

.button__style-2--active,
.button__style-3--active,
.button__style-5--active {
  background-color: rgba(255, 199, 0, 0.1);
  color: var(--c-yellow);
  pointer-events: initial;
}

.button__style-5--active svg path {
  fill: var(--c-yellow);
}

.button__style-5--success {
  background-color: rgba(11, 229, 203, 0.05);
  color: var(--c-aqua);
  pointer-events: none;
}

.button__style-5--success svg path {
  fill: var(--c-aqua);
}

.back__button {
  /* border: 1px solid magenta; */
  width: 1.8rem;
  height: 1.8rem;
}

.back__button--small {
  width: 1.4rem;
  height: 1.4rem;
}

.back__button svg {
  width: 100%;
  height: 100%;
}

/* Global style for head Nav */
.lease__t-head {
  /* border: 1px solid magenta; */
  position: sticky;
  top: 0;
}

.lease__t-head .head__main-tab {
  /* border: 1px solid magenta; */
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 3rem 2rem 1rem 2rem;
  background-color: var(--c-navy);
  /* z-index: 20; */
  position: relative;
  /* margin-bottom: 2rem; */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

.lease__t-head .head__main-tab--2 h1 {
  font-size: var(--f-size-3);
  font-weight: var(--f-weight-1);
}

.lease__t-head .head__main-tab .button__main-tab {
  white-space: nowrap;
  background-color: transparent;
  color: var(--c-white-3);
  padding: 0.6rem 1rem;
  border: none;
  outline: none;
  border-radius: 0.6rem;
  font-family: var(--f-family-2);
  font-size: var(--f-size-5);
  font-weight: var(--f-weight-1);
}

.lease__t-head .head__main-tab .button__main-tab--active {
  color: var(--c-navy);
  background-color: var(--c-yellow);
}

.lease__t-head .head__sub-tab {
  width: 100%;
  /* overflow-y: scroll; */
  background-color: #1c263e;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  /* border: 1px solid magenta; */
}

.head__sub-tab .head__sub-tab-outlet--2 {
  /* border: 1px solid magenta; */
  position: relative;
  width: max-content;
  padding: 1rem 2rem;
  display: flex;
  gap: 1.4rem;
}

.head__sub-tab .head__sub-tab-outlet--1 {
  /* border: 1px solid magenta; */
  width: 100%;
  padding: 1rem 2rem;
}

.head__sub-tab .head__sub-tab-outlet--1 .button__sub-tab {
  white-space: nowrap;
}

/* splide */
.head__sub-tab .head__sub-tab-outlet--1 .splide .splide__slide {
  width: unset !important;
  margin-right: 1.4rem;
}

.search-browse__icons-container .splide .splide__slide {
  width: unset !important;
  margin-right: 1.4rem;
}

/* .splide .splide__slide {
  width: unset !important;
  margin-right: 1.4rem;
} */
/* splide */

/* .head__sub-tab .head__sub-tab-outlet .button__sub-tab {
  background-color: var(--c-white-2);
  color: var(--c-white-3);
  padding: 1.2rem 1rem;
  border: none;
  outline: none;
  border-radius: 0.6rem;
  font-family: var(--f-family-2);
  font-size: var(--f-size-5);
} */
.button__sub-tab {
  /* white-space: nowrap; */
  background-color: var(--c-white-2);
  color: var(--c-white-3);
  padding: 1.2rem 1rem;
  border: none;
  outline: none;
  border-radius: 0.6rem;
  font-family: var(--f-family-2);
  font-size: var(--f-size-5);
}

.button__sub-tab--browse {
  /* white-space: nowrap; */
  background-color: var(--c-white-2);
  color: var(--c-white-3);
  padding: 0 1rem;
  height: 2.8rem;
  border: none;
  outline: none;
  font-family: var(--f-family-2);
  font-size: var(--f-size-5);
  border-radius: 99rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
}

.head__sub-tab .head__sub-tab-outlet--2 {
  position: initial;
  width: 100%;
}

.head__sub-tab .head__sub-tab-outlet--2 .button__sub-tab {
  white-space: initial;
}

/* .head__sub-tab .head__sub-tab-outlet .button__sub-tab--active {
  background-color: rgba(255, 199, 0, 0.1);
  color: var(--c-yellow);
} */
.button__sub-tab--active {
  background-color: rgba(255, 199, 0, 0.1);
  color: var(--c-yellow);
}

.button__sub-tab--browse .sub__wrapp {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
}

.button__sub-tab--active svg path {
  fill: var(--c-yellow);
}

/* info Box */
.info-box {
  /* border: 1px solid magenta; */
  /* margin-top: 2.5rem; */
  background-color: var(--c-white-2);
  padding: 0.6rem 1rem;
  border-radius: 0.6rem;
  display: flex;
  gap: 1rem;
  font-size: var(--f-size-5);
  font-family: var(--f-family-2);
}

.info-box-colored {
  background-color: var(--c-white-2);
  padding: 1.3rem 1.4rem;
  border-radius: 0.6rem;
  font-size: var(--f-size-5);
  font-family: var(--f-family-2);
}

.info-box-colored_heading {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--c-yellow);
  margin-bottom: 1rem;
}

.info-box-icon_colored svg path {
  fill: var(--c-yellow);
}

.info-box .info-box__icon {
  opacity: 0.6;
}

.info-box p {
  flex: 1;
  /* opacity: .6; */
  color: rgba(255, 255, 255, 0.6);
  line-height: 1.6rem;
}

.info-box p span {
  opacity: 1;
  color: var(--c-white);
}

select {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: none;
}
