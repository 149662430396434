.invlist {
	/* border: 1px solid white; */
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.invlist-body {
	/* border: 1px solid magenta; */
	flex: 1;
	overflow-y: auto;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
}
