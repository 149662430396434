.current3 {
	/* border: 1px solid magenta; */
}

.current3__open {
	/* border: 1px solid magenta; */
	margin-top: 4rem;
}

.current3__open .head__title {
	font-size: var(--f-size-2);
	font-weight: var(--f-weight-1);
	margin-bottom: 1.2rem;
}

.current3__open .open__container {
	/* border: 1px solid magenta; */
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.6rem;
}

.current3__open .open__items .open__year {
	padding: .3rem .8rem;
	background-color: rgba(255, 199, 0, 0.1);
	font-size: var(--f-size-4);
	font-weight: var(--f-weight-2);
	border-radius: 99rem;
	color: var(--c-yellow);
}

.current3__open .open__wrapper {
	/* border: 1px solid magenta; */
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.open__wrapper .open__card {
	/* border: 1px solid magenta; */
	display: flex;
	gap: 1.2rem;
	padding: 1.1rem 1.2rem;
	border-radius: .6rem;
	background-color: rgba(255, 199, 0, 0.1);
}

/* .open__wrapper .open__card .card__left { */
	/* border: 1px solid magenta; */
/* } */

.open__wrapper .open__card .card__left .profile {
	width: 2.8rem;
	height: 2.8rem;
	background-color: rgba(255, 199, 0, 0.1);
	border-radius: 99rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* .open__wrapper .open__card .card__right { */
	/* border: 1px solid magenta; */
/* } */

.open__wrapper .open__card .card__right .date {
	/* border: 1px solid magenta; */
	display: flex;
	align-items: center;
	gap: .8rem;
	margin-bottom: 1rem;
}

.open__wrapper .open__card .card__right .date p {
	/* border: 1px solid magenta; */
	color: var(--c-white-3);
	font-family: var(--f-family-2);
}

.open__wrapper .open__card .card__right .date span {
	width: .2rem;
	height: .2rem;
	background-color: var(--c-white-3);
}

.open__wrapper .open__card .card__right .title {
	/* border: 1px solid magenta; */
	margin-bottom: 1.35rem;
}

.open__wrapper .open__card .card__right .title .p1 {
	/* border: 1px solid magenta; */
	color: var(--c-yellow);
	font-family: var(--f-family-2);
	font-size: var(--f-size-3);
	margin-bottom: .6rem;
}

.open__wrapper .open__card .card__right .title__info {
	/* border: 1px solid magenta; */
	display: flex;
	align-items: center;
	gap: 1.2rem;
}

.open__wrapper .open__card .card__right .title__info .label__wrapper {
	/* border: 1px solid magenta; */
	display: flex;
	align-items: center;
	gap: .4rem;
	font-weight: var(--f-weight-2);
}

.open__wrapper .open__card .card__right .title__info .label__wrapper .label {
	/* border: 1px solid magenta; */
	width: 1.6rem;
	height: .8rem;
	background-color: red;
	border-radius: 99rem;
}

.open__wrapper .open__card .card__right .title__info .label--contract .label {
	background-color: #9D70FF;
}
.open__wrapper .open__card .card__right .title__info .label--defects .label {
	background-color: var(--c-orange);
}

.open__wrapper .open__card .card__right .title__info .label--contract p {
	color: #9D70FF;
}
.open__wrapper .open__card .card__right .title__info .label--defects p {
	color: var(--c-orange);
}

.open__wrapper .open__card .card__right .message {
	/* border: 1px solid magenta; */
	margin-bottom: 1.2rem;
}

.open__wrapper .open__card .card__right .message .message__title {
	color: var(--c-yellow);
	font-family: var(--f-family-2);
	margin-bottom: .6rem;
}
.open__wrapper .open__card .card__right .message .message__card {
	/* border: 1px solid magenta; */
	display: flex;
	gap: .6rem;
}

/* .open__card .card__right .message .message__card .left { */
	/* border: 1px solid magenta; */
/* } */

.open__card .card__right .message .message__card .left__profile {
	width: 2.8rem;
	height: 2.8rem;
	background-color: rgba(255, 199, 0, 0.1);
	border-radius: 99rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.open__card .card__right .message .message__card .right {
	/* border: 1px solid magenta; */
}

.open__card .card__right .message .message__card .right .right__date {
	/* border: 1px solid magenta; */
	display: flex;
	align-items: center;
	gap: .8rem;
	margin-bottom: 1rem;
}

.open__card .card__right .message .message__card .right .right__date p {
	/* border: 1px solid magenta; */
	color: var(--c-white-3);
	font-family: var(--f-family-2);
}

.open__card .card__right .message .message__card .right .right__date span {
	width: .2rem;
	height: .2rem;
	background-color: var(--c-white-3);
}

.open__card .card__right .message .message__card .right .right__text {
	font-weight: var(--f-weight-2);
	font-family: var(--f-family-2);

	display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.open__wrapper .open__card .card__right .actions {
	/* border: 1px solid magenta; */
	display: flex;
	gap: 1.6rem;
}