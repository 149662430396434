.modsys {
	position: fixed;
	top: 0; left: 0; right: 0; bottom: 0;
	/* border: 1px solid magenta; */
	display: flex;
	justify-content: center;
	align-items: center;
}

.modsys .modsys__overlay {
	position: absolute;
	top: 0; left: 0; right: 0; bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modsys .modsys__box {
	position: relative;
	z-index: 1;
	/* border: 1px solid magenta; */
	width: 100%;
	max-width: 32rem;
	padding: 2rem;
	background-color: #26282E;
	border-radius: .6rem;
}

.modsys .modsys__box .modsys__icon {
	/* border: 1px solid magenta; */
	margin-bottom: 1rem;
}

.modsys .modsys__box .modsys__text {
	/* border: 1px solid magenta; */
	margin-bottom: 1rem;
	color: white;
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
}

.modsys .modsys__box .modsys__text p:nth-child(1) {
	font-size: var(--f-size-3);
	font-weight: var(--f-weight-1);
}

.modsys .modsys__box .modsys__text p:nth-child(2) {
	font-weight: var(--f-weight-3);
	line-height: 1.6rem;
	font-family: var(--f-family-2);
	font-size: var(--f-size-5);
}

.modsys .modsys__box .modsys__actions {
	/* border: 1px solid magenta; */
	display: flex;
	justify-content: flex-end;
	gap: 1rem;
}

.modsys .modsys__box .modsys__actions .modsys__btn {
	cursor: pointer;
	height: 4rem;
	padding: 0 1.4rem;
	font-size: var(--f-size-4);
	font-family: var(--f-family-1);
	font-weight: var(--f-weight-1);
	border-radius: .6rem;
	outline: none;
	border: none;
	background-color: var(--c-white-2);
	color: var(--c-white);
}

.modsys .modsys__box .modsys__actions .modsys__btn--active {
	background-color: rgba(255, 199, 0, 0.1);
	color: var(--c-yellow);
}