.amenities {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
}

.amenities__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.1rem 1.4rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.6rem;
  gap: 1.2rem;
}

.amenities__list__active {
  background: rgba(255, 199, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.1rem 1.4rem;
  border-radius: 0.6rem;
  gap: 1.2rem;
}

.amenities__list-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.6rem;
  width: 100%;
  position: relative;
  font-weight: 700;
  cursor: pointer;
}

.amenities__list-title__active {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.6rem;
  width: 100%;
  position: relative;
  font-weight: 700;
  color: var(--c-yellow);
}

.amenities__list-title__active path {
  fill: var(--c-yellow);
}

.amenities__list-point {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: var(--f-size-5);
  font-weight: var(--f-weight-2);
}

.amenities__list-chevron {
  margin-left: auto;
}

.amenities__list-point li {
  list-style-image: url('../../../../icon/general/Vector-42-check.svg');
  font-weight: 400;
  font-size: 1.4rem;
}
