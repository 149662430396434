.breakdownchart {
	/* border: 1px solid magenta; */
	display: flex;
	flex-direction: column;
	gap: 1.4rem;
}

.breakdownchart .breakdownchart__title {
	font-size: var(--f-size-2);
	font-weight: var(--f-weight-1);
}

.breakdownchart__container {
	/* border: 1px solid magenta; */
	display: flex;
	align-items: center;
	gap: 2rem;
}

.breakdownchart__container .chart {
	/* border: 1px solid magenta; */
	/* width: 100px; */
	width: 100%;
	max-width: 6.4rem;
	height: 6.4rem;
	aspect-ratio: 1 / 1;
}

.breakdownchart__container .chart canvas {
	/* width: 100%; */
}

.breakdownchart__container .chart__detail {
	/* border: 1px solid magenta; */
	display: flex;
	flex-wrap: wrap;
	gap: 1.2rem;
}

/* label */
.breakdownchart__container .detail__label {
	/* border: 1px solid magenta; */
	flex: 1;
	display: flex;
	gap: .6rem;
}

.breakdownchart__container .label {
	width: 1.6rem;
	height: .8rem;
	background-color: var(--c-white);
	border-radius: 99rem;
}

.breakdownchart__container .label__info {
	/* border: 1px solid magenta; */
}

.breakdownchart__container .label__info .info__title {
	font-size: var(--f-size-6);
	font-weight: var(--f-weight-2);
	font-family: var(--f-family-2);
	width: 100%;
	display: inline-block;
}

.breakdownchart__container .label__info .info__money {
	font-size: var(--f-size-4);
	font-weight: var(--f-weight-2);
	font-family: var(--f-family-2);
}

.breakdownchart__container .label__info .info__money span {
	font-size: var(--f-size-6);
}

.breakdownchart__container .label-stat-1 {
	background-color: var(--c-yellow);
}

.breakdownchart__container .label-stat-2 {
	background-color: var(--c-white);
}

.breakdownchart__container .label-stat-3 {
	background-color: var(--c-purple-1);
}

.breakdownchart__container .label-stat-4 {
	background-color: var(--c-blue-1);
}

.breakdownchart__container .label-stat-5 {
	background-color: var(--c-aqua);
}

.breakdownchart__container .label__info .info__title-stat-1 {
	color: var(--c-yellow);
}

.breakdownchart__container .label__info .info__title-stat-2 {
	color: var(--c-white);
}

.breakdownchart__container .label__info .info__title-stat-3 {
	color: var(--c-purple-1);
}

.breakdownchart__container .label__info .info__title-stat-4 {
	color: var(--c-blue-1);
}

.breakdownchart__container .label__info .info__title-stat-5 {
	color: var(--c-aqua);
}