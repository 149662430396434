.browse-filter-body {
  position: relative;
  /* border: 1px solid magenta; */
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.browse-filter-page {
  /* padding: 2rem 2rem 10rem 2rem; */
  /* border: 1px solid blue; */
  flex: 1;
  overflow-y: auto;

}

.browse-filter-page .form-filter {
  /* border: 1px solid magenta; */
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

}

.browse-filter-page .form-filter__wrapper {
  padding: 2rem;
  /* border: 1px solid green; */
  /* background-color: rgba(255, 255, 255, 0.144); */
  flex: 1;
  /* height: 100px; */
}

.browse-option-container {
  background: var(--c-white-2);
  padding: 1.1rem 1.4rem;
  border-radius: 0.6rem;
  margin-bottom: 1.6rem;
}

.option-row {
  display: flex;
}

/* The container */
.filter-checkbox-container {
  margin: 1rem 0rem;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.browse-filter-page input[type='checkbox']:checked ~ label {
  background: rgba(255, 199, 0, 0.1);
  color: var(--c-yellow);
}

/* Hide the browser's default checkbox */
.filter-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  /* height: 0.5rem;
  width: 0.5rem; */
}

.button-row {
  display: flex;
  gap: 1rem;
}


.type-option_item-container .option-1,
.type-option_item-container .option-2 {
  display: flex;
  flex-direction: column;
  gap: .4rem;
}

.browse-filter-page .schedule-button {
  position: absolute;
  bottom: 0;
  left: 0; right: 0;
  /* border: 1px solid magenta; */
  flex: 2;
}