.modal-request__main {
  position: relative;
  color: var(--c-white);
  height: 100vh;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
}

.modal-manage__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.622);
  z-index: -1;
}

.modal-manage-request {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  height: 100vh;
}

.modal-content__icon-container {
  height: 1.9rem;
}

.modal-content__rejection-title {
  font-size: 1.4rem;
  font-weight: var(--f-weight-1);
  color: var(--c-white);
}

.modal-content__rejection-input {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.modal-content__rejection-dropdown {
  border: 0.1rem solid white;
  border-radius: 0.6rem;
  padding: 1.2rem 1.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: var(--f-weight-1);
  font-size: 1.4rem;
}

.modal-content__extend {
  display: flex;
  gap: 1.2rem;
  align-items: center;
}

.modal-content__extend-item {
  width: fit-content;
  padding: 1.1rem 1.4rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
}

.modal-content__extend-time {
  font-size: 1.4rem;
  line-height: 1.609rem;
}

.modal-content__container {
  background-color: #26282e;
  padding: 4.5rem 2rem;
  width: 28rem;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* border: 1px solid magenta; */
}

.modal-content__title-text {
  font-size: 2rem;
  color: var(--c-white);
  font-weight: var(--f-weight-1);
}

.modal-content__paragraph-text {
  font-size: 1.2rem;
  color: var(--c-white);
  line-height: 1.6rem;
}

.modal-content__paragraph-yellow {
  color: var(--c-yellow);
  font-weight: var(--f-weight-1);
}

.form-input-manage-offer label select {
  width: 100% !important;
  border-right: 1px solid var(--c-white);
  border-radius: 0.6rem;
  margin-top: 0;
  cursor: pointer;
}

.manage-request__accept-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
}

.manage-request__reject-btn-2,
.manage-request__reject-btn-2-arr {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  /* fill: var(--c-yellow); */
}

.manage-request__reject-btn-2-arr svg {
  transform: rotate(-180deg);
  /* fill: var(--c-yellow); */
}

.manage-request__accept-btn svg path {
  fill: var(--c-yellow);
}

.btn-no-active {
  opacity: 0.5;
  pointer-events: none;
}

.extension__label {
  position: relative;
  /* border: 1px solid magenta; */
  color: var(--c-white);
  cursor: pointer;
}

.extension__label input[type='radio'] {
  position: absolute;
  opacity: 0;
}

.extension__label input[type='radio']:checked ~ .modal-content__extend-item {
  background: rgba(255, 199, 0, 0.1);
  border: 1px solid var(--c-yellow);
  color: var(--c-yellow);
}
