.service-bottom_button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem 1.6rem;
  background-color: var(--c-black-1);
}

.service-box {
  background: var(--c-white-2);
  padding: 1rem 1.4rem;
  margin-bottom: 1.6rem;
  border-radius: 0.6rem;
}

.service-bottom_button-item {
  display: flex;
  gap: 1rem;
}

.service-box_item {
  margin-bottom: 1rem;
}

.guarantee_item-container {
  padding: 0rem 1.5rem;
  font-family: var(--f-family-2);
}

.quotation-information {
  margin: 1.2rem 0;
}

.quotation-container {
  display: grid;
  grid-template-columns: repeat(5, 6rem);
  grid-template-rows: repeat(5, 2rem);
  grid-column-gap: 0.5rem;
  grid-row-gap: 2rem;
  font-family: var(--f-family-2);
  margin-top: 1rem;
  word-wrap: break-word;
}

.quotation-title {
  color: rgba(255, 255, 255, 0.6);
}

.quotation-item {
  border: 1px solid red;
  line-height: 2rem;
}
