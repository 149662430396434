.account_main {
  display: flex;
  flex-direction: column;
}

.account_header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0rem;
  gap: 1rem;
  background: #1f2944;
  width: 100%;
  height: auto;
  padding: 3rem 2rem 1rem;
  gap: 1.2rem;
}

.account_header-upper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0rem;
  gap: 1rem;
  width: inherit;
}

.account_header-upper-user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0rem;
  gap: .5rem;
}

.account_header-upper-user-username {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0rem;
  gap: .6rem;
  width: max-content;
}

.account_header-upper-user-username svg path {
  fill: var(--c-yellow);
}

.account_header-upper-user-username-initial {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: .7rem .2rem;
  gap: 1rem;
  width: 3rem;
  height: 3rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 9.9rem;
}

.account_header-upper-user-username-initial h4{
  font-weight: 500;
  font-family: var(--f-family-2);
  font-size: 1.2rem;
}

.account_header-upper-user-username h2 {
  font-size: 2rem;
  font-weight: 700;
}

.account_header-upper-user-rating {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0rem;
  gap: .2rem;
  opacity: 60%;
  font-size: 1.4rem;
  font-weight: 400;
  font-family: var(--f-family-2);
}

.account_header-upper-notification {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.account_header-upper-notification button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 9.9rem;
  border-color: transparent;
  cursor: pointer;
}

.account_header-upper-notification button:active {
  background-color: rgba(255, 255, 255, 0.13);
  transition: all 0.1s ease-in-out;
}

.account_header-upper-notification svg {
  width: 1.3rem;
  height: 1.6rem;
}

.account_header-switch {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: .6rem;
  height: 2.5rem;
  width: fit-content;
  font-family: var(--f-family-2);
}

.account_header-switch .icon-switch {
  cursor: pointer;
}

.account_header-switch input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: .1rem;
  width: .1rem;
  border: 0;
  overflow: hidden;
}

.account_header-switch label {
  background-color: rgba(255, 255, 255, 0.05);
  color: #bbbbbb;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  padding: .6rem 1rem;
  margin-right: -.1rem;
  border: .1rem solid rgba(0, 0, 0, 0.2);
  border-radius: .6rem;
  transition: all 0.3s ease-in-out;
}

.account_header-switch label:hover {
  cursor: pointer;
}

.account_header-switch input:checked + label {
  background-color: #ffc700;
  color: #1f2944;
  font-weight: 500;
}

.account_header-assets {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0rem;
  gap: 2rem;
}

.account_header-assets-value {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0rem;
  gap: .2rem;
}

.account_header-assets-value h2 {
  font-size: 1.6rem;
  font-weight: 500;
}

.account_header-assets-value h4 {
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}

.account_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 1.2rem;
  width: 100%;
  position: relative;
}

.account_body-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: .6rem 1.5rem;
  gap: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: .6rem;
  width: 100%;
  position: relative;
}

.account_body-container-warn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0rem;
  gap: .6rem;
  opacity: 60%;
  font-size: 1.2rem;
  font-family: var(--f-family-2);
}

.account_body-container-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0rem;
  cursor: pointer;
  width: inherit;
}

.account_link {
  color: #ffffff;
  width: 100%;
}

.account_body-container-list-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0rem;
  gap: .6rem;
}

.account_body-container-list-title svg {
  width: 1.2rem;
  height: 1.2rem;
}

.account_body-container-list-title p {
  font-size: 1.6rem;
  font-weight: 700;
}

.account_body-container-list-detail {
  margin-left: auto;
}

.account_body-container-list-detail svg {
  width: 1.6rem;
  height: 1.6rem;
}

.account-body-container-uncompleted {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: .6rem 1rem;
  color: #ffc700;
  background: rgba(255, 199, 0, 0.1);
  border-radius: 9.9rem;
  margin-left: 1rem;
}

.account-body-container-uncompleted p {
  font-size: 1.2rem;
  font-weight: 400;
}
