.onboarding__outlet-3 {
	/* border: 1px solid magenta; */
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.onboarding__outlet-3-head {
	/* border: 1px solid magenta; */
}

.onboarding__outlet-3-head h1 {
	/* border: 1px solid magenta; */
	font-size: var(--f-size-3);
	font-weight: var(--f-weight-1);
}

.onboarding__outlet-3-head .p1 {
	margin-top: .2rem;
	font-size: var(--f-size-5);
}

.onboarding__outlet-3-personal,
.onboarding__outlet-3-identity {
	/* border: 1px solid magenta; */
	padding: 1.1rem 1.4rem;
	background-color: var(--c-white-2);
	border-radius: .6rem;
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
}

.onboarding__outlet-3-personal .personal__head,
.onboarding__outlet-3-identity .identity__head {
	/* border: 1px solid magenta; */
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.onboarding__outlet-3-personal .personal__head p,
.onboarding__outlet-3-identity .identity__head p {
	font-size: var(--f-size-3);
	font-weight: var(--f-weight-1);
}

.onboarding__outlet-3-personal .personal__info p:nth-child(1) {
	font-size: var(--f-size-6);
	font-weight: var(--f-weight-2);
}

.onboarding__outlet-3-personal .personal__info p:nth-child(2) {
	font-size: var(--f-size-4);
	font-weight: var(--f-weight-2);
	font-family: var(--f-family-2);
}

.onboarding__outlet-3-identity .identity__proof {
	/* border: 1px solid magenta; */
	display: flex;
	flex-direction: column;
	gap: .6rem;
}

.onboarding__outlet-3-identity .identity__proof .identity__preview {
	/* border: 1px solid magenta; */
	padding: .9rem .8rem;
	background-color: var(--c-white-2);
}

.onboarding__outlet-3-identity .identity__proof .identity__preview-image {
	width: 4.2rem;
	height: 4.2rem;
	background-color: var(--c-yellow);
	border-radius: .3rem;
}

.onboarding__outlet-3-identity .identity__proof .identity__preview-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}



.onboarding__outlet-3 .personal__edit,
.onboarding__outlet-3 .identity__edit {
	background-color: transparent;
	border: none;
	outline: none;
}


