.precon {
	height: 100vh;
	display: flex;
	flex-direction: column;
	/* border: 1px solid magenta; */
}

.precon-body {
	/* border: 1px solid mediumslateblue; */
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	flex: 1;
	overflow-y: auto;
}