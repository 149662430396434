/* .card-property {
  height: 100%;
  //padding: 2rem 2rem 9.1rem 2rem;
  //overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;
} */

.card-property__link {
  color: var(--c-white-1);
}

.card-property__card {
  display: flex;
  flex-direction: column;
  background-color: var(--c-white-2);
  border-radius: 0.6rem;
  overflow: hidden;
  /* border: 1px solid magenta; */
  width: 100%;
}

.card__image {
  /* border: 1px solid magenta; */
  position: relative;
}

.card__image .card__image-badge {
  position: absolute;
  /* border: 1px solid magenta; */
  top: 1.5rem;
  left: 1.5rem;
  z-index: 2;
  background-color: #26282e;
  display: flex;
  align-items: center;
  gap: 0.55rem;
  padding: 0.3rem 0.7rem;
  border-radius: 10rem;
  font-size: var(--f-size-5);
  font-weight: var(--f-weight-2);
  color: var(--c-yellow);
  pointer-events: none;
}

.card__image .card__image-badge svg path {
  fill: var(--c-yellow);
}

.card__image .card__image-info {
  position: absolute;
  /* border: 1px solid magenta; */
  bottom: 0;
  left: 0;
  right: 0;
  height: 9rem;
  display: flex;
  align-items: flex-end;
  padding: 1rem 1.5rem;
  pointer-events: none;
  background: linear-gradient(180deg, rgba(31, 41, 68, 0) 0%, #080b12 100%);
}

.card__image .card__image-info-wrapper {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid magenta; */
  width: 100%;
  pointer-events: none;
}

.card__image .card__image-info-wrapper div:nth-child(1),
.card__image .card__image-info-wrapper div:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  font-size: var(--f-size-4);
  font-weight: var(--f-weight-1);
  color: var(--c-white-3);
}

.card__image .card__image-info-wrapper div:nth-child(1) {
  font-weight: var(--f-weight-2);
  font-family: var(--f-family-2);
}

.card__image .card__image-info-wrapper div:nth-child(1) svg path,
.card__image .card__image-info-wrapper div:nth-child(2) svg path {
  fill: var(--c-white-3);
}

/* splide */
.card__image .card__image-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card__image .card__image-slide .splide {
  width: 100%;
}

.card__image .card__image-slide .splide .splide__slide img {
  /* height: 22.6rem; */
  aspect-ratio: 319 / 226;
}

.card__image .splide__arrow {
  /* border: 1px solid magenta; */
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.2rem;
  height: 2.2rem;
}

.card__image .splide__arrow svg {
  fill: var(--c-navy);
}

.card__image  .splide__arrow:hover:not(:disabled) svg {
  fill: var(--c-navy);
}

/* .card__image .splide__arrow--prev::after {
  content: url(../../../icon/general/arrow-prev.svg);
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid magenta;
}

.card__image .splide__arrow--next::after {
  content: url(../../../icon/general/arrow-next.svg);
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
} */

.card__image .splide__arrow--prev::after,
.card__image .splide__arrow--next::after {
}
/* splide */

.card-property__detail {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* border: 1px solid magenta; */
}

.detail__button-flag {
  justify-content: space-between;
  display: flex;
  align-items: center;
  /* border: 1px solid magenta; */
}

.flag__train {
  padding: 0.3rem 0.6rem 0.3rem 0.2rem;
  border-radius: 10rem;
  background-color: var(--c-white-2);
  display: flex;
  gap: 0.6rem;
  align-items: center;
  justify-content: center;
  font-family: var(--f-family-2);
}

.flag__train div {
  padding: 0.2rem 0.5rem;
  border-radius: 10rem;
  background-color: var(--c-blue);
  font-size: 0.8rem;
}

.flag__train .p1 {
  font-size: var(--f-size-6);
}

.detail__button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.7rem;
}

.detail__button-container button {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
}

.detail__button-container svg path {
  fill: var(--c-yellow);
}

.detail__property-name-price {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid magenta; */
}

.property-name {
  width: 100%;
  max-width: 18rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  /* border: 1px solid magenta; */
}

.property-name h1 {
  font-size: var(--f-size-3);
  font-weight: var(--f-weight-1);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.property-name p {
  font-size: var(--f-size-5);
  font-weight: var(--f-weight-3);
  font-family: var(--f-family-2);
}

.property-price {
  flex: 1;
  text-align: right;
  /* border: 1px solid magenta; */
}

.property-price h1 {
  font-size: var(--f-size-4);
  font-weight: var(--f-weight-2);
  font-family: var(--f-family-2);
}

.property-price h1 span {
  font-size: var(--f-size-6);
}

.detail__property-feature {
  display: flex;
  align-items: center;
  gap: 1.1rem;
  /* border: 1px solid magenta; */

  font-size: var(--f-size-5);
  font-weight: var(--f-weight-3);
  font-family: var(--f-family-2);
}

.detail__feature {
  display: flex;
  gap: 3px;
  align-items: center;
  /* border: 1px solid magenta; */
}

.detail__feature:nth-child(2) {
  margin-right: 0.5rem;
}

.detail__feature .feature__icon {
  width: 1.5rem;
  height: 1.5rem;
  /* border: 1px solid magenta; */
}

.detail__feature .feature__icon svg {
  width: 100%;
  height: 100%;
}

.detail__property-bottom {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 10px;
  /* border: 1px solid magenta; */
  font-size: var(--f-size-6);
  font-weight: var(--f-weight-3);
  font-family: var(--f-family-2);
}

.bottom-interact {
  display: flex;
  align-items: center;
  gap: 3px;
}

.bottom-interact p {
  color: var(--c-white-3);
}

.bottom-interact .interact__icon {
  width: 1.1rem;
  height: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid magenta; */
}

.bottom-interact .interact__icon--plus {
  width: 0.8rem;
  height: 0.8rem;
  /* border: 1px solid magenta; */
}

.bottom-interact .interact__icon svg {
  width: 100%;
  height: 100%;
}

.bottom-interact svg path {
  fill: var(--c-white-3);
}


@media only screen and (min-width: 580px) {
  .card-property__card {
    color: var(--c-navy);
    box-shadow: 0px 0px 20px rgba(31, 41, 68, 0.25);
  } 

  .flag__train {
    background-color: rgba(31, 41, 68, 0.1);
  }

  .flag__train .flag-label {
    color: var(--c-white);
  }

  .detail__button-container svg path {
    fill: var(--c-navy);
  }

  .detail__property-feature .feature__icon svg path {
    fill: var(--c-navy);
  }

  .detail__property-bottom .bottom-interact p {
    color: rgba(31, 41, 68, 0.6);
  }

  .detail__property-bottom .bottom-interact svg path {
    fill: rgba(31, 41, 68, 0.6);
  }
}