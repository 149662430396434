.text-2xl {
  font-size: var(--f-size-1);
}

.text-xl {
  font-size: var(--f-size-2);
}

.text-lg {
  font-size: var(--f-size-3);
}

.text-md {
  font-size: var(--f-size-4);
}

.text-sm {
  font-size: var(--f-size-5);
}

.text-xs {
  font-size: var(--f-size-6);
}

.bold {
  font-weight: var(--f-weight-1);
}

.normal {
  font-weight: var(--f-weight-2);
}

.thin {
  font-weight: var(--f-weight-3);
}
.lease {
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* background-color: red; */
}

.lease-top-menu {
  background: #1f2944;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  display: flex;
  gap: 1.5rem;
  padding-top: 3rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  font-weight: var(--f-weight-1);
  color: rgba(255, 255, 255, 0.6);
  /* border: 1px solid magenta; */
  position: relative;
  z-index: 1;
}

.lease-top-menu-item {
  font-size: 1.2rem;
  padding: 1rem;
  border-radius: 6px;
  cursor: pointer;
}

.lease-top-menu-item.lease-menu-active {
  background: #ffc700;
  color: #1f2944;
}










.lease-listed-item {
  display: flex;
  gap: 1.2rem;
}

.add-list-option {
  display: flex;
  gap: 1.2rem;
}

.lease-button-icon-colored svg path {
  fill: var(--c-yellow);
}

.box-add-lease-icon svg path {
  fill: var(--c-yellow);
}

.lease-listed-information-box {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  padding: 2.4rem 2rem;
}

.lease-listed-information-box_heading {
  font-size: var(--f-size-2);
  font-weight: var(--f-weight-1);
}

.lease-listed-information-box_desc {
  font-size: var(--f-size-5);
  font-family: var(--f-family-2);
  font-weight: var(--f-weight-3);
  line-height: 1.6rem;
  margin-top: 1rem;
}

.lease-title {
  font-size: var(--f-size-1);
  font-weight: var(--f-weight-1);
}

.lease-listed-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* border: 1px solid magenta; */
  overflow-y: auto;
}

.lease-content {
  padding: 2rem 2rem 10rem 2rem;
  /* border: 1px solid magenta; */
  flex: 1;
  /* background-color: red; */
  overflow-y: auto;
}

.lease-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  flex: 1;
}

.lease-summary-information {
  /* margin-top: 1.4rem; */
  background: var(--c-white-2);
  padding: 1.2rem 1.4rem;
  border-radius: 6px;

  display: flex;
  justify-content: space-between;
}

.lease-summary-information_item {
  font-size: var(--f-size-6);
  font-family: var(--f-family-2);
  font-weight: var(--f-weight-2);
  line-height: 2rem;
}

.lease-unit-statistics-container {
  padding: 1.4rem;
  background: #26282e;
}

.lease-unit-statistics-title {
  color: var(--c-yellow);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.lease-unit-statistics-title svg path {
  fill: var(--c-yellow);
}

.lease-unit-statistics-detail {
  display: flex;
  gap: 2rem;
  margin: 1rem 0;
  align-items: center;
}

.lease-unit-statistics-detail-item {
  font-family: var(--f-family-2);
  line-height: 1.6rem;
}

.lease-unit-card-information-details-facilities {
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.lease-unit-card-information_details-facilities-item {
  display: flex;
  gap: 1rem;
}

.lease-property__link {
  color: var(--c-white-1);
}