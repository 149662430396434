.browse-page {
  height: 100vh;
  display: flex;
  /* border: 1px solid magenta; */
  flex-direction: column;
}

.browse-page__con {
  flex: 1;
  overflow-y: scroll;
  padding: 2rem;
  padding-bottom: 9rem;
  /* border: 1px solid magenta; */

}

.browse-page .con-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* padding: 2rem 0; */
}

.browse-page .browse-page__body {
  height: 100%;
  /* padding: 2rem; */
  /* height: calc(100vh - (16rem + 7.1rem)); */
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  /* border: 1px solid magenta; */
  /* background-color: red; */
  /* flex: 1; */
  gap: 2rem;
  /* padding-bottom: 9rem; */
}

/* .browse-page .browse-page__body-wrapp {
  display: flex;
  border: 1px solid red;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  background-color: red;
  gap: 2rem;
} */

.card-property__relative-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: .6rem;
  /* border: 1px solid magenta; */
}

.card-property__relative-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-property__blurred-text-container {
  position: absolute;
  bottom: 0;
  left: 0; right: 0;
  top: 0;
  padding: 0 2rem 4.6rem 2rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: flex-end;
  /* border: 1px solid magenta; */
}

.card-property__blurred-text-title {
  font-size: 2.4rem;
  font-weight: var(--f-weight-1);
}

.card-property__blurred-text {
  font-size: 1.4rem;
}

.card-property__blurred-button-container {
  display: flex;
  gap: 1.6rem;
  padding-top: 1rem;
}

.card-property__blurred-button {
  width: 13.2rem;
}

.con-body__list-info-container {
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 2rem; */
  display: none;
}

.con-body__list-info-container .search-browse__list-card {
  background-color: rgba(31, 41, 68, 0.1);
  color: var(--c-navy);
}

.con-body__list-info-container .search-browse__list-card svg path {
  fill: var(--c-navy);
}

.con-body__list-info-container .search-browse__list-filter {
  background-color: var(--c-navy);
}

@media only screen and (min-width: 580px) {
  .browse-page .browse-page__con {
    padding-bottom: 2rem;
    display: flex;
    flex: 1;
  }

  .browse-page .browse-page__body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-rows: auto 1fr; */
    grid-auto-rows: min-content;
    /* grid-template-rows: max-content; */
    gap: 2rem;
    /* grid-auto-rows: max-content; */
    overflow-y: auto;
    flex: 1.3;
  }

  .con-body__list-info-container {
    display: flex;
  }
}

@media only screen and (min-width: 768px) {


  /* .browse-page .browse-page__body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  } */
}

@media only screen and (min-width: 1024px) {
  .browse-page .browse-page__con {
		padding: 0 8rem;
  }

  .browse-page .browse-page__con {
    /* padding-bottom: 2rem; */
    /* display: flex; */
    gap: 2rem;
  }

  .browse-page .con-body {
    padding: 2rem 0;
  }
}

@media only screen and (min-width: 1848px) {
  .browse-page .browse-page__body {
    grid-template-columns: repeat(3, 1fr);
  }
}