.maps-web {
	display: none;
	/* border: 1px solid magenta; */
}

.maps-web img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media only screen and (min-width: 1024px) {
	.maps-web {
		display: block;
		flex: 1;
	}
}