.service-content {
  padding: 2rem 2rem 10rem 2rem;
}

.information-box-direct {
  color: var(--c-yellow);
}

.service-card-item {
  padding: 1.1rem 1.2rem;
  background: rgba(255, 255, 255, 0.05);
  margin: 2rem 0;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  gap: 1.3rem;
  justify-content: space-between;
}

.service-card-title {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex: 1;
}

.service-card-title_icon svg {
  width: 2rem;
  height: 2rem;
}
