/* .modal-request__main {
  position: relative;
} */

.manage-request,
.manage-request__back-button {
  width: 100%;
}

/* .modal-manage-request {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.622);
  z-index: 10;
  height: 100vh;
}

.modal-content__icon-container {
  height: 1.9rem;
}

.modal-content__rejection-title {
  font-size: 1.4rem;
  font-weight: var(--f-weight-1);
}

.modal-content__rejection-input {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.modal-content__rejection-dropdown {
  border: 0.1rem solid white;
  border-radius: 0.6rem;
  padding: 1.2rem 1.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: var(--f-weight-1);
  font-size: 1.4rem;
} */

.optional {
  color: #ffffff99;
}

/* .modal-content__extend {
  display: flex;
  gap: 1.2rem;
  align-items: center;
}

.modal-content__extend-item {
  width: fit-content;
  padding: 1.1rem 1.4rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
}

.modal-content__extend-time {
  font-size: 1.4rem;
  line-height: 1.609rem;
}

.modal-content__container {
  background-color: #26282e;
  padding: 4.5rem 2rem;
  width: 28rem;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.modal-content__title-text {
  font-size: 2rem;
  font-weight: var(--f-weight-1);
}

.modal-content__paragraph-text {
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.modal-content__paragraph-yellow {
  color: var(--c-yellow);
  font-weight: var(--f-weight-1);
} */

.manage-request {
  background-color: var(--c-navy);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 3rem 2rem 1rem;
  box-shadow: 0px 4px 10px #00000051;
  top: 0;
  /* position: sticky; */
  z-index: 1;
}

.manage-request__back-button {
  display: flex;
  flex-direction: row;
  gap: 9px;
  align-items: center;
}

.manage-request__back-icon path {
  width: 2rem;
  height: 2rem;
}

.manage-request__back-text {
  font-size: 2rem;
  font-weight: var(--f-weight-2);
}

.manage-request__tab-switch-container {
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  width: 100%;
}

.manage-request__tab-switch-item {
  padding: 0.6rem 1rem;
  cursor: pointer;
}

.manage-request__tab-switch-text {
  font-size: 1.2rem;
}
.manage-request__tab-switch-item-active {
  background-color: var(--c-yellow);
  padding: 0.6rem 1rem;
  border-radius: 6px;
  cursor: pointer;
}

.manage-request__tab-switch-item .manage-request__tab-switch-text {
  color: rgba(255, 255, 255, 0.6);
  font-weight: var(--f-weight-1);
}

.manage-request__tab-switch-item-active .manage-request__tab-switch-text {
  color: var(--c-navy);
  font-weight: var(--f-weight-1);
}

.manage-request__body {
  flex: 1;
  padding: 2rem;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
}

.manage-request__section-container {
  padding: 1.1rem 1.4rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.manage-request__flex-container {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
}

.manage-request__reminder-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 17.2rem;
  align-self: flex-start;
}

.manage-request__reminder-text-upper {
  font-size: 1.6rem;
  font-weight: var(--f-weight-1);
}

.manage-request__reminder-text-down {
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.manage-request__date-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
}

.manage-request__date-text {
  font-size: 2rem;
  font-weight: var(--f-weight-1);
}

.manage-request__day-container {
  padding: 0.3rem 0.8rem;
  background-color: rgba(255, 199, 0, 0.05);
  border-radius: 99px;
}

.manage-request__day-text {
  font-size: 1.4rem;
  color: var(--c-yellow);
  font-weight: var(--f-weight-1);
}

.manage-request__request-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.manage-request__request-time-text {
  font-size: 1.2rem;
  font-weight: var(--f-weight-2);
}

.manage-request__status-container {
  display: flex;
  gap: 0.4rem;
}

.manage-request__status-dot {
  display: block;
  width: 1.6rem;
  height: 0.8rem;
  border-radius: 99px;
}

.accepted {
  background-color: var(--c-aqua);
}

.accepted-text {
  color: var(--c-aqua);
}

.pending {
  background-color: var(--c-blue-1);
}

.pending-text {
  color: var(--c-blue-1);
}

.manage-request__name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manage-request__name-text {
  font-size: 1.4rem;
  font-weight: var(--f-weight-2);
}

.manage-request__comment-container {
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
}

.manage-request__comment-counter {
  padding: 0.2rem 0.8rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 99px;
}

.manage-request__comment-icon {
  cursor: pointer;
}
.manage-request__comment-icon path {
  fill: var(--c-yellow);
}

.manage-request__request-item {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.manage-request__warning-container {
  background-color: rgba(252, 104, 45, 0.05);
  padding: 0.6rem 1rem;
  display: flex;
  gap: 0.6rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.6rem;
}

.manage-request__warning-icon path {
  fill: #fc682d;
}

.manage-request__warning-text {
  color: #fc682d;
  font-size: 1rem;
}

.gap8 {
  gap: 0.8rem !important;
}

.manage-request__btn-container {
  display: flex;
  gap: 1.2rem;
}

.manage-request__reject-btn,
.manage-request__accept-btn {
  padding: 1.2rem 1.4rem;
  border-radius: 6px;
  font-weight: var(--f-weight-2);
  border: none;
  outline: none;
}

.manage-request__reject-btn {
  background-color: rgba(255, 255, 255, 0.05);
  color: white;
}

.manage-request__accept-btn {
  background-color: rgba(255, 199, 0, 0.1);
  color: var(--c-yellow);
}

.manage-request__currency {
  font-size: 1rem;
}

.manage-request__mny-amount {
  font-size: 1.6rem;
}
