/* .current2 { */
	/* border: 1px solid magenta; */
/* } */

.current2 .schedule__items {
	/* border: 1px solid magenta; */
	margin-top: 4rem;
}
.current2 .schedule__items .head__title {
	/* border: 1px solid magenta; */
	font-size: var(--f-size-2);
	font-weight: var(--f-weight-1);
	margin-bottom: 1.2rem;
}

.current2 .schedule__container {
	/* border: 1px solid magenta; */
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.6rem;
}

.current2 .schedule__container .schedule__year {
	/* border: 1px solid magenta; */
	padding: .3rem .8rem;
	background-color: rgba(255, 199, 0, 0.1);
	font-size: var(--f-size-4);
	font-weight: var(--f-weight-2);
	border-radius: 99rem;
	color: var(--c-yellow);
}

.current2 .schedule__container .schedule__wrapper {
	/* border: 1px solid magenta; */
	display: flex;
	flex-direction: column;
	gap: 2rem;
	width: 100%;
}

.current2 .schedule__container .schedule__card {
	/* border: 1px solid magenta; */
	display: flex;
	gap: 1.2rem;
	padding: 1.1rem 1.2rem;
	border-radius: .6rem;
	background-color: rgba(255, 199, 0, 0.1);
}


/* .current2 .schedule__card .card__left { */
	/* border: 1px solid magenta; */
/* } */

.current2 .schedule__card .card__left .date {
	/* border: 1px solid magenta; */
}

.current2 .schedule__card .card__left .date .date__month {
	/* border: 1px solid magenta; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: .2rem;
	font-size: var(--f-size-2);
	font-family: var(--f-family-2);
	font-weight: var(--f-weight-2);
}

.current2 .schedule__card .card__left .date .date__month span:nth-child(2) {
	font-size: var(--f-size-4);
}

.current2 .schedule__card .card__left .date .date__day {
	margin-top: .4rem;
	/* border: 1px solid magenta; */
	padding: .3rem .8rem;
	background-color: rgba(255, 199, 0, 0.1);
	font-size: var(--f-size-4);
	font-weight: var(--f-weight-2);
	border-radius: 99rem;
	color: var(--c-yellow);
}

.current2 .schedule__card .card__right {
	/* border: 1px solid magenta; */
	flex: 1;
}

.current2 .schedule__card .card__right .time-label {
	/* border: 1px solid magenta; */
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.current2 .schedule__card .card__right span {
	font-size: var(--f-size-4);
	font-family: var(--f-family-2);
	font-weight: var(--f-weight-2);
}

.current2 .schedule__card .card__right .label__wrapper {
	display: flex;
	align-items: center;
	gap: .4rem;
	font-weight: var(--f-weight-2);
}

.current2 .schedule__card .card__right .label__wrapper .label {
	width: 1.6rem;
	height: .8rem;
	background-color: var(--c-white-2);
	border-radius: 99rem;
}

.current2 .schedule__card .card__right .label--contract .label {
	background-color: #9D70FF;
}
.current2 .schedule__card .card__right .label--contract p {
	color: #9D70FF;
}


.current2 .schedule__card .card__right .content {
	/* border: 1px solid magenta; */
	margin-bottom: 2rem;
	font-family: var(--f-family-2);
	display: flex;
	flex-direction: column;
	gap: .2rem;
}
.current2 .schedule__card .card__right .content span:nth-child(1) {
	font-size: var(--f-size-6);
	color: var(--c-white-3);
	font-weight: var(--f-weight-2);
}
.current2 .schedule__card .card__right .content span:nth-child(2) {
	font-size: var(--f-size-3);
	color: var(--c-yellow);
	font-weight: var(--f-weight-2);
}
.current2 .schedule__card .card__right .actions {
	/* border: 1px solid magenta; */
	display: flex;
	gap: 1.6rem;
}

.current2 .schedule__container .schedule__card--proof {
	/* border: 1px solid magenta; */
	background-color: rgba(31, 162, 255, 0.05);
}
.current2 .schedule__container .schedule__card--complete {
	/* border: 1px solid magenta; */
	background-color: rgba(11, 229, 203, 0.05);
}
.current2 .schedule__card--proof .card__right .content span:nth-child(2),
.current2 .schedule__card--complete .card__right .content span:nth-child(2) {
	/* border: 1px solid magenta; */
	color: var(--c-white-1);
}
