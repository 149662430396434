.addrequest {
	/* border: 1px solid magenta; */
	position: relative;
	/* border: 1px solid magenta; */
	min-height: 100vh;
}

.addrequest .addrequest__body {
	/* border: 1px solid magenta; */
	padding: 2rem;
	height: calc(100vh - (6.4rem + 6.8rem));
	overflow-y: auto;
}

.addrequest .addrequest__body .req__form {
	/* border: 1px solid magenta; */
	padding: 1.1rem 1.4rem;
	border-radius: .6rem;
	background-color: var(--c-white-2);

	display: flex;
	flex-direction: column;
	gap: 2rem;
}

/* .addrequest__body .req__field { */
	/* border: 1px solid magenta; */
/* } */

.addrequest__body .req__field p {
	/* border: 1px solid magenta; */
	font-size: var(--f-size-4);
	font-weight: var(--f-weight-1);
	margin-bottom: .8rem;
}

.addrequest__body .req__field-category {
	display: flex;
	gap: 1.2rem;
	flex-wrap: wrap;
}

.addrequest__body .req__field-category button {
	/* width: 100%; */
	padding: .6rem 1rem;
	border-radius: 99rem;
	border: none;
	outline: none;
	background-color: var(--c-white-2);
	color: var(--c-white-3);
	font-size: var(--f-size-5);
	/* font-weight: var(--f-weight-1); */
	font-family: var(--f-family-1);
	pointer-events: none;
}