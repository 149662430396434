.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.modal__content {
  /* border: 1px solid magenta; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: calc(36rem - 8rem);
  background-color: #26282e;
  border-radius: 0.6rem;
  padding: 4.5rem 2rem;
  color: white;
}

.modal__content .content__text h1 {
  font-size: var(--f-size-2);
  font-weight: var(--f-weight-1);
  margin-bottom: 1rem;
}

.modal__content .content__text p {
  font-size: var(--f-size-5);
  /* font-weight: var(--f-weight-1); */
  font-family: var(--f-family-2);
  line-height: 1.6rem;
}

.modal__content .content__text span {
  color: var(--c-yellow);
}

.content__text-bankVerification {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.modal__content .content__handler {
  /* border: 1px solid magenta; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.modal__content .content__handler--sign {
  /* border: 1px solid magenta; */
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 2rem;
}

.services-advantage {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.services-advantage_item {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: var(--f-size-4);
  font-family: var(--f-family-2);
}

.modal__content .content__head {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal__content .content__head span:nth-child(1) {
  font-size: var(--f-size-3);
  font-weight: var(--f-weight-1);
}

.modal__content .content__head span:nth-child(2) {
  font-size: var(--f-size-5);
}

.content__sign {
  background-color: var(--c-white);
  /* border: 1px solid magenta; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.6rem;
  overflow: hidden;
}
.content__sign .sigCanvas {
  /* border: 1px solid magenta; */
  width: 100%;
  height: 12rem;
}

.modal__reject,
.modal__title,
.modal__reject-form,
.reject-form,
.modal__timeSlot-form,
.timeSlot-form,
.modal__chat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  position: relative;
  width: 100%;
}

.modal__reject {
  max-height: calc(100vh - 16rem);
  overflow-y: auto;
}

.modal__title {
  gap: 0.8rem;
}

.modal__title svg {
  margin-bottom: 1.2rem;
  height: 1.8rem;
  width: 1.8rem;
}

.modal__title .text-xl {
  font-weight: 700;
}

.reject-form .form-input, .timeSlot-form .form-input {
  width: 100%;
  position: relative;
}

.reject-form .form-input label,
.report-user-form label, .timeSlot-form .form-input label {
  font-weight: 700;
  font-size: var(--f-size-4);
}

.reject-form .form-input select,
.report-user-form select,
.modal__timeSlot-form .form-input input {
  margin-top: 1rem;
}

.reject-form .form-input .date-pick-reject, .timeSlot-form .form-input {
  margin-top: 1rem;
  cursor: pointer;
}

.reject-form .form-input .calendar-icon, .timeSlot-form .form-input .calendar-icon {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 4rem;
  bottom: 0;
}

.timeSlot-container,
.quota-container {
  background-color: transparent;
  border: 0.1rem solid var(--c-white);
  padding: 1.2rem 1.4rem;
  border-radius: 0.6rem;
  width: 100%;
  font-family: var(--f-family-1);
  color: var(--c-white);
  font-size: var(--f-size-4);
  outline: none;
}

.form-comment {
  background-color: transparent;
  border: 1px solid var(--c-white);
  padding: 1.2rem 1.4rem;
  margin-top: 1rem;
  border-radius: 0.6rem;
  width: 100%;
  height: 12rem;
  font-family: var(--f-family-1);
  color: var(--c-white);
  font-size: var(--f-size-5);
  outline: none;
}

.comment-char-count {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  opacity: 60%;
  font-weight: 500;
}

.modal__chat-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.1rem 1.4rem;
  gap: 1.2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.6rem;
  width: 100%;
}

.chat-detail-user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  gap: 0.5rem;
  font-weight: 500;
}

.chat-detail-user-username {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 0.6rem;
  width: 100%;
}

.chat-detail-user-username .text-xl {
  font-weight: 700;
}

.chat-detail-user-username svg path {
  fill: var(--c-yellow);
}

.chat-detail-user-username-initial {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 0.2rem;
  gap: 1rem;
  width: 3rem;
  height: 3rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 9.9rem;
}

.chat-detail-user-rating {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  gap: 0.2rem;
  opacity: 60%;
  font-size: 1.4rem;
}

.chat-detail-row-section {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  font-weight: 500;
}

.chat-detail-col-section {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  font-weight: 500;
}

.chat-detail-flag {
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  white-space: nowrap;
}

.chat-detail-flag div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0.5rem;
  gap: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 9.9rem;
  font-family: var(--f-family-2);
  font-size: 1.2rem;
  opacity: 60%;
}

.report-user-form-label {
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
}

.report-user-form .form-input span, .timeSlot-form span {
  opacity: 60%;
}

.content__body{
  display: flex;
  flex-direction: column;
  gap: .8rem;
  font-weight: 700;
}

.boost-row-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.2rem;
}

.boost-option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1.1rem 1.4rem;
  gap: 1.3rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.6rem;
  font-family: var(--f-family-2);
  font-weight: 500;
}

.boost-option-selected{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem 1.3rem;
  gap: 1.3rem;
  background: rgba(255, 199, 0, 0.1);
  border-radius: 0.6rem;
  font-family: var(--f-family-2);
  font-weight: 500;
  border: .1rem solid var(--c-yellow);
}

.boost-option-plan {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.boost-option-price {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 1.2rem 1.4rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.6rem;
  white-space: nowrap;
}

.content__handler .button__style-5{
  padding: 1.4rem 0;
  font-size: var(--f-size-5);
}

.form-input--textarea textarea {
  margin-top: 1rem;
  resize: none;
}

.content-logout{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 800;
}