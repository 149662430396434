.snackbar {
	/* border: 1px solid magenta; */
	min-height: 4rem;
	width: calc(100% - 4rem);
	max-width: 36rem;
	background-color: #1F2944;
	color: var(--c-yellow);
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
	border-radius: .6rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 2rem;
	border: 1px solid var(--c-yellow);
	gap: 1.2rem;
	opacity: 0;
	transform: translateY(6.5rem);
	transition: all .5s ease;
	pointer-events: none;
}

.snackbar--active {
	opacity: 1;
	pointer-events: initial;
	transition: all .5s ease;
	transform: translateY(8rem);
}

.snackbar .snackbar__icon svg path {
	fill: var(--c-yellow);
}

.snackbar .snackbar__text {
	/* border: 1px solid magenta; */
	flex: 1;
	font-size: var(--f-size-5);
}