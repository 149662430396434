.chat {
  position: relative;
  /* border: 1px solid magenta; */
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.chat-container {
  /* flex: 1; */
  height: calc(100vh - 14.1rem);
  /* border: 1px solid magenta; */
  /* background-color: rgba(250, 235, 215, 0.226); */
  display: flex;
  flex-direction: column;
}

.chat-header-container {
  background: var(--c-navy);
  padding: 3rem 2rem 1rem 2rem;
  display: flex;
  gap: 1rem;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

.chat-property.chat-active {
  border: 2px solid var(--c-yellow);
  border-radius: 0.6rem;
  opacity: 1;
  color: rgba(0, 0, 0, 0.69);
}

.chat-property {
  opacity: 0.6;
  position: relative;

  font-family: var(--f-family-2);
  font-size: var(--f-size-5);
  color: rgba(255, 255, 255, 0.6);
  font-weight: var(--f-weight-2);
}

.chat-property_name {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  align-items: center;
}

.chat-property_image {
  width: 8rem;
  height: 8rem;
  border-radius: 0.6rem;
  overflow: hidden;
}

.chat-property_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chat-menu-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
  padding: 2rem 1.2rem 1.2rem;
}

.chat-body {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  flex: 1;
  /* height: 100px; */

  /* background-color: rgba(158, 104, 39, 0.164); */
  /* gap: 1.2rem; */
  overflow-y: auto;
  padding-bottom: 4rem;
}

.chat-information-container,
.chat-year-container,
.chat-time-container,
.chat-unread-container {
  display: flex;
  justify-content: center;
  /* padding: 1rem 0; */
}

.chat-menu-item {
  padding: 1rem;
  border-radius: 0.6rem;
  background: var(--c-white-2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-information {
  border-radius: 0.6rem;
  background: var(--c-white-2);
  display: flex;
  padding: 0.8rem 1rem;
  gap: 1rem;
}

.chat-information .chat-information_icon {
  opacity: 0.6;
}

.chat-information_value {
  color: rgba(255, 255, 255, 0.6);
  font-family: var(--f-family-2);
  font-weight: var(--f-weight-2);
  font-size: var(--f-size-5);
}

.chat-information_user {
  color: var(--c-yellow);
}

.chat-messages {
  /* border: 1px solid blue; */
  flex: 1;
  overflow-y: auto;
  /* height: 100px; */
  /* background-color: rgba(153, 205, 50, 0.158); */
  /* height: 100%; */
}

.chat-messages .chat-messages__info {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 1.2rem 0;
}


.chat-messages .chat-messages__text {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  /* padding: 1.2rem 0; */
}

.chat-year {
  font-family: var(--f-family-2);
  font-size: var(--f-size-6);
  color: var(--c-yellow);
  background: rgba(255, 199, 0, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 99px;
  /* border: 1px solid magenta; */
}

.chat-time {
  display: flex;
  font-family: var(--f-family-2);
  color: rgba(255, 255, 255, 0.6);
  font-weight: var(--f-weight-2);
  font-size: var(--f-size-6);
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.chat-time_dot {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 99px;
  background-color: rgba(255, 255, 255, 0.6);
}

.chat-bubble-container {
  /* border: 1px solid magenta; */
}

.chat-bubble_receiver {
  display: flex;
  gap: .6rem;
  padding: 0 2rem;
  align-items: flex-end;
  /* border: 1px solid magenta; */
}

.chat-bubble_receiver .buble-wrapper {
  display: flex;
  flex-direction: column;
  gap: .8rem;
  /* border: 1px solid magenta; */
}

.chat-bubble_sender {
  color: var(--c-yellow);
  /* background: rgba(255, 199, 0, 0.1); */
  display: flex;
  flex-direction: column;
  gap: .8rem;
  align-items: flex-end;
  padding: 0 2rem;
  /* border: 1px solid magenta; */
}

.chat-bubble_message-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.chat-bubble_message {
  background: var(--c-white-2);
  font-family: var(--f-family-2);
  font-size: var(--f-size-5);
  font-weight: var(--f-weight-3);
  padding: 1.1rem 1.4rem;
  border-radius: 0.6rem;
  line-height: 1.4rem;
  width: 82%;
}

.chat-bubble_sender .chat-sender {
  background: rgba(255, 199, 0, 0.1);
  width: 73%;
}

.chat-unread_label {
  color: var(--c-red);
  font-family: var(--f-family-2);
  background: var(--c-white-2);
  font-weight: var(--f-weight-2);
  font-size: var(--f-size-6);
  padding: 0.5rem 1rem;
  border-radius: 99px;
}

.chat-footer-container {
  background: var(--c-black-1);
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: sticky; */
  /* bottom: 7rem; */
  /* margin-top: 2rem; */
  /* margin-bottom: 7.1rem; */
  width: 100%;
  opacity: .5;
  /* flex: 2; */
  height: 7rem;
  /* border: 1px solid magenta; */
}

.chat-footer-input_container {
  border: 1px solid var(--c-white);
  position: relative;
  border-radius: 0.6rem;
  width: 100%;
}

.chat-footer-input {
  border: none;
  width: 80%;
  height: 4rem;
  background: transparent;
  color: var(--c-white);
  padding: 0 1.5rem 0 5rem;
  font-size: var(--f-size-4);
}

.chat-footer-icon_send {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
}

.chat-footer-icon_emoji {
  position: absolute;
  top: 50%;
  left: 2rem;
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
}

.chat-footer-icon_send svg,
.chat-footer-icon_emoji svg {
  width: 100%;
  height: 100%;
}
