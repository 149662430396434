.button-lease {
  font-size: var(--f-size-4);
  font-weight: var(--f-weight-1);
  background: rgba(255, 199, 0, 0.1);
  border-radius: 0.6rem;
  padding: 1.2rem 1.4rem;
  color: var(--c-yellow);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.button-lease-secondary {
  background: rgba(255, 255, 255, 0.05);
  color: white;
}
