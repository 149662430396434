.navbar-web {
	/* border: 1px solid magenta; */
	display: flex;
	justify-content: space-between;
	background-color: white;
	padding: 2rem;
	padding-bottom: 0;
	align-items: center;
	position: relative;
	z-index: 20;
}

.navbar-web img {
	width: 100%;
	max-width: 10rem;
}

.navbar-web .navbar-web__links {
	/* border: 1px solid magenta; */
	display: flex;
	gap: 6rem;
}

.navbar-web .navbar-web__links a {
	font-size: var(--f-size-3);
	font-weight: 600;
	color: var(--c-navy);
}

@media only screen and (max-width: 580px) {
  .navbar-web {
		display: none;
  }
}

/* @media only screen and (min-width: 768px) {
  .navbar-web {
		padding: 2rem 8rem;
		padding-bottom: 0;
  }
} */

@media only screen and (min-width: 1024px) {
  .navbar-web {
		padding: 2rem 8rem;
		padding-bottom: 0;
  }
}