.started {
  /* border: 1px solid blue; */
  min-height: 100vh;
  padding: 4rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.started__logo {
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.started__type {
  /* border: 1px solid magenta; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.started__type-title h1 {
  font-size: var(--f-size-1);
  font-weight: var(--f-weight-1);
  margin-bottom: 1rem;
}

.started__type-title p {
  font-size: var(--f-size-3);
  font-weight: var(--f-weight-1);
}

.started__type-pick {
  /* border: 1px solid magenta; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.started__type-pick button {
  padding: 2rem 1.1rem;
  width: 100%;
  border-radius: 0.6rem;
  outline: none;
  background-color: var(--c-white-2);
  border: none;
  color: var(--c-white);
  text-align: left;
  font-weight: var(--f-weight-1);
  font-size: var(--f-size-3);
}

.pick__active {
  background-color: rgba(255, 199, 0, 0.1) !important;
  border: 1px solid var(--c-yellow) !important;
}

.started__type-pick .type-pick__icon {
  width: 100%;
}

.started__type-info {
  background-color: var(--c-white-2);
  padding: 0.6rem 1rem;
  display: flex;
  gap: 1rem;
  font-size: var(--f-size-5);
  font-family: var(--f-family-2);
  border-radius: 0.6rem;
}

.started__type-info .type-info__icon {
  opacity: 0.6;
}

.started__type-info p {
  flex: 1;
  opacity: 0.6;
  line-height: 1.6rem;
}

.started__button {
  /* border: 1px solid magenta; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
}