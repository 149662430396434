.profile_main {
  display: flex;
  flex-direction: column;
  position: relative;
}

.profile_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  padding-bottom: 8.8rem;
  gap: 4rem;
  height: calc(100vh - 6.4rem);
  overflow-y: scroll;
}

h3 {
  font-weight: 700;
  font-size: 1.6rem;
}

.profile_body-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.profile_body-form .form-input, .profile_body-form .form-input input.input__text{
  font-family: var(--f-family-2);
}

.form-input__input-number,
.form-input__input-email {
  position: relative;
  width: 100%;
}

.form-input__number input.input__text {
  width: 100%;
}

.input_check-icon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 110%;
  margin-right: 1.4rem;
  display: flex;
  align-items: center;
  width: 1.6rem;
}

.input_check-icon svg {
  width: 100%;
  height: 100%;
}

.input_check-icon svg path {
  fill: var(--c-yellow);
}

.profile_body-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0rem;
  gap: 2rem;
  width: -webkit-fill-available;
}

.profile_body-details-verifying {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  width: inherit;
  font-family: var(--f-family-2);
}

.profile_body-details-verifying p {
  font-weight: 400;
  font-size: 1.2rem;
  opacity: 60%;
}

.profile_body-details-verifying svg {
  width: 1.6rem;
  height: 1.6rem;
  opacity: 60%;
}

.profile_body-details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.1rem 1.4rem;
  gap: 1.6rem;
  width: inherit;
  background: rgba(255, 255, 255, 0.05);
  border-radius: .6rem;
}

.profile_body-details-information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0rem;
  gap: .4rem;
  font-weight: 500;
  font-family: var(--f-family-2);
}

.profile_body-details-identity {
  display: flex;
  flex-direction: column;
  padding: 0rem;
  gap: .6rem;
  width: inherit;
  font-weight: 500;
  font-family: var(--f-family-2);
}

.profile_body-details-identity-preview {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: .9rem .8rem;
  gap: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: .6rem;
}

.profile_body-details-identity-preview-image {
  width: 4.2rem;
  height: 4.2rem;
  background-color: var(--c-yellow);
  border-radius: .3rem;
}

.profile_body-details-identity-preview-image img {
  width: 100%;
	height: 100%;
	object-fit: cover;
}

.profile_button {
  position: absolute;
  display: inline-block;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: var(--c-black-2);
}
