.lease__t-ag {
	position: relative;
	height: 100vh;
	display: flex;
	flex-direction: column;
}


/* .lease__t-head-ag { */
	/* border: 1px solid magenta; */
/* } */

.lease__t-body-ag {
	/* border: 1px solid magenta; */
	position: relative;
	flex: 1;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.lease__t-body-ag .lease__t-ag-box {
	padding: 1.1rem 1.4rem;
	background-color: var(--c-white-2);
	border-radius: .6rem;
}


.lease__t-body-ag .accept__btn {
	cursor: pointer;
	z-index: 2;
	opacity: .1 !important;
}

.agreeement-btn-ag {
	position: initial !important;
}