.signup {
  /* border: 1px solid blue; */
  min-height: 100vh;
  padding: 4rem 2.5rem 2.5rem 2.5rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.signup__head {
  /* border: 1px solid magenta; */
}

.signup__head-title h1 {
  font-size: var(--f-size-1);
  font-weight: var(--f-weight-1);
}

.signup__head-title p {
  font-size: var(--f-size-3);
  font-weight: var(--f-weight-1);
}

.signup__form-wrapper {
  /* border: 1px solid magenta; */
  padding: 0 1.5rem;
}

.signup__form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.signup__alternative {
  /* border: 1px solid magenta; */
  padding: 0 1.5rem;
  text-align: center;
}

.signup__alternative-options {
  /* border: 1px solid magenta; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.signup__alternative-options button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--c-white-2);
  border: none;
  outline: none;
  height: 3.6rem;
  padding: 0 2.5rem;
  border-radius: 0.6rem;
}

.signup__alternative p {
  margin-bottom: 0.6rem;
  font-size: var(--f-size-5);
  font-family: var(--f-family-2);
}

.signup__policy {
  /* border: 1px solid magenta; */
  padding: 0 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.signup__policy p:nth-child(1) {
  font-size: var(--f-size-5);
  font-weight: var(--f-weight-3);
  line-height: 1.6rem;
}

.signup__policy p:nth-child(1) a,
.signup__policy p:nth-child(2) a {
  color: var(--c-yellow);
  text-decoration: none;
}

.signup__policy p:nth-child(2) {
  font-size: var(--f-size-3);
  font-weight: var(--f-weight-1);
  line-height: 1.6rem;
}

.signup__skip {
  /* border: 1px solid magenta; */
  padding: 0 1.5rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  font-size: var(--f-size-3);
  font-weight: var(--f-weight-1);
}
