.payment {
	position: relative;
	/* border: 1px solid magenta; */
	height: 100vh;
}

.payment__body {
	/* border: 1px solid magenta; */
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	height: calc(100vh - (6.8rem + 6.4rem));
	overflow-y: auto;
}

.payment__payable {
	/* border: 1px solid magenta; */
	padding: 1.1rem 1.4rem;
	background-color: var(--c-white-2);
	border-radius: .6rem;
}

.payment__payable .payable__title {
	font-size: var(--f-size-3);
	font-weight: var(--f-weight-1);
	/* border: 1px solid magenta; */
	margin-bottom: 2rem;
	display: block;
}

.payment__payable .payment__wrapper {
	border: 1px solid var(--c-yellow);
	border-radius: .6rem;
	padding: 1.3rem 1.4rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

/* .payment__payable .payment__wrapper .payable__pay { */
	/* border: 1px solid magenta; */
/* } */

.payment__payable .payment__wrapper .payable__pay:nth-child(3) {
	color: var(--c-yellow);
}

.payment__payable .payment__wrapper .payable__pay div:nth-child(1) {
	/* border: 1px solid magenta; */
	font-size: var(--f-size-4);
	font-weight: var(--f-weight-2);
	font-family: var(--f-family-2);
	display: flex;
	justify-content: space-between;
}

.payment__payable .payment__wrapper .payable__pay div:nth-child(1) span:nth-child(2) span {
	font-size: var(--f-size-5);
}

.payment__payable .payment__wrapper .payable__pay div:nth-child(2) {
	margin-top: .25rem;
	font-size: var(--f-size-6);
	font-weight: var(--f-weight-2);
	font-family: var(--f-family-2);
	color: var(--c-white-3);
	display: flex;
	align-items: center;
	gap: .45rem;
	text-transform: uppercase;
}

.payment__payable .payment__wrapper .payable__pay div:nth-child(2) .payable__icon {
	/* border: 1px solid magenta; */
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1.1rem;
	height: 1.1rem;
}

.payment__payable .payment__wrapper .payable__pay div:nth-child(2) .payable__icon svg path {
	fill: var(--c-white-3);
}

.payment__payable .payment__tabs {
	/* border: 1px solid magenta; */
	display: flex;
	justify-content: space-between;
	gap: 2rem;
}

.payment__payable .payment__tabs .tab__payment {
	/* width: 100%; */
	padding: .6rem 0;
	border-radius: .6rem;
	border: none;
	/* border: 1px solid magenta; */
	outline: none;
	background-color: transparent;
	color: var(--c-white-3);
	font-size: var(--f-size-5);
	font-weight: var(--f-weight-1);
	font-family: var(--f-family-1);
	/* pointer-events: none; */
	display: flex;
	align-items: center;
	gap: .5rem;
	cursor: pointer;
}

.payment__payable .payment__tabs .tab__payment--active {
	padding: .6rem 1rem;
	background-color: var(--c-yellow);
	color: var(--c-navy);
}

.payment__payable .payment__tabs .tab__payment svg path { 
	fill: rgba(255, 255, 255, 0.6);
}

.payment__payable .payment__tabs .tab__payment--active svg path {
	fill: var(--c-navy);
}

.payment__payable .payment__outlet {
	/* border: 1px solid magenta; */
	margin-top: 2rem;
}

.payment__payable .payment__outlet-1 {
	/* border: 1px solid magenta; */
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.payment__payable .payment__outlet-1 .number__cards {
	/* border: 1px solid magenta; */
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.payment__payable .payment__outlet-1 .number__cards .card__payment {
	/* border: 1px solid magenta; */
	display: flex;
	justify-content: space-between;
	padding: 1.4rem 1.4rem 1.1rem 1.4rem;

	border-radius: .6rem;
	border: none;
	/* border: 1px solid magenta; */
	outline: none;
	background-color: var(--c-white-2);
	color: var(--c-white-3);
	font-size: var(--f-size-4);
	font-weight: var(--f-weight-2);
	font-family: var(--f-family-2);
}

.payment__payable .payment__outlet-1 .number__cards .card__payment--active {
	background-color:  rgba(255, 199, 0, 0.1);
	color: var(--c-yellow);
	border: 1px solid var(--c-yellow);
}

.payment__payable .payment__outlet-1 .number__cards .card__payment div:nth-child(1) {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	/* gap: 1rem; */
	/* border: 1px solid magenta; */
}

.payment__payable .payment__outlet-1 .number__cards .card__payment div:nth-child(1) p:nth-child(2) {
	font-size: var(--f-size-6);
}

/* payment outlet 1 */

.payment__outlet-1 form {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}