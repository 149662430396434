.search-browse {
  background-color: var(--c-navy);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  padding: 3rem 2rem 1rem;
  box-shadow: 0px 4px 10px #00000051;
  /* top: 0; */
  /* position: sticky; */
  z-index: 1;
  /* border: 1px solid magenta; */
}

.search-browse__input_container,
.search-browse__icons-container,
.search-browse__tab-container,
.search-head__back-button {
  width: 100%;
  /* border: 1px solid magenta; */
}

.search-head__back-button {
  display: flex;
  flex-direction: row;
  gap: 9px;
  align-items: center;
}

.search-head__back-icon path {
  width: 2rem;
  height: 2rem;
}

.search-browse__tab-container {
  display: flex;
  gap: 12px;
}

.search-head__back-text {
  font-size: 2rem;
  font-weight: var(--f-weight-1);
}

.search-head__tab-button {
  padding: 6px 10px;
  align-items: center;
  cursor: pointer;
}

.search-head__tab-button h1 {
  font-weight: var(--f-weight-1);
}

.search-head__tab-btn-active {
  padding: 6px 10px;
  border-radius: 6px;
  background-color: var(--c-yellow);
  cursor: pointer;
}

.search-head__tab-btn-active h1 {
  color: var(--c-navy);
  font-weight: var(--f-weight-1);
}

/* Search Input */
.search-browse__input-container {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
  /* border: 1px solid magenta; */
}

.search-head__search-input {
  display: flex;
  padding: 0.5rem;
  border-radius: 0.6rem;
  border: 1px solid var(--c-white);
  width: 100%;
  align-items: center;
}

.search-input__lens {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid white; */
  height: 2rem;
  width: 2rem;
}

.search-input__input {
  width: 100%;
}

.search-slide-browse {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


/* .search-browse__icons-container { */
  /* border: 1px solid magenta; */
/* } */


@media only screen and (min-width: 580px) {
  .search-browse {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.6rem;
    background-color: var(--c-white);
    padding: 2rem;
  }

  .search-browse__input-container {
    flex: 1.5;
    min-width: 30rem;
  }

  .search-browse__icons-container {
    flex: 1;
  }

  .search-browse__icons-container .button__sub-tab--browse {
    height: 4rem;
    min-width: 4rem;
    border-radius: .6rem;
    background-color: rgba(31, 41, 68, 0.1);
  }

  .search-browse__icons-container .button__sub-tab--browse svg path {
    fill: var(--c-navy);
  }

  .search-browse__icons-container .button__sub-tab--active {
    background-color: rgba(255, 199, 0, 0.1);
  }

  .search-browse__icons-container .button__sub-tab--active svg path {
    fill: var(--c-yellow);
  }
}

/* @media only screen and (min-width: 768px) {
  .search-browse {
    padding: 2rem 8rem;
  }
} */

@media only screen and (min-width: 580px) {
  .search-browse__icons-container .splide .splide__slide {
    margin: 0;
  }

  .search-browse__icons-container .splide .splide__list {
    display: flex;
    gap: 2rem;
  }
}

@media only screen and (min-width: 1024px) {
  .search-browse {
		padding: 2rem 8rem;
		/* padding-bottom: 0; */
  }


}