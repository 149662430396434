.signup__features {
	display: flex;
	gap: 1.5rem;
	/* border: 1px solid magenta; */
	margin-top: 2.5rem;
}

.signup__features-illus {
	/* border: 1px solid magenta; */
}

.signup__features-illus svg {
	width: 100%;
}

.signup__features-list {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: .8rem;
	/* border: 1px solid magenta; */
}

.signup__features-list .features-list__item {
	display: flex;
	gap: 1rem;
}

.signup__features-list .features-list__item svg {
	min-width: 1.2rem;
}

.signup__features-list .features-list__item svg path {
	fill: var(--c-yellow);
}

.signup__features-list .features-list__item p {
	color: var(--c-yellow);
	font-size: var(--f-size-4);
}