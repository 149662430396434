@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}
:root {
  --c-white: #ffffff;
  --c-white-2: rgba(255, 255, 255, 0.05);
  --c-white-3: rgba(255, 255, 255, 0.5);
  --c-black-1: #000000;
  --c-black-2: #080b12;
  --c-navy: #1f2944;
  --c-yellow: #ffc700;
  --c-orange: #f99d25;
  --c-red: #e22726;
  --c-purple: #8f4199;
  --c-purple-1: #9d70ff;
  --c-blue: #005da8;
  --c-blue-1: #1fa2ff;
  --c-brown: #9d5b25;
  --c-aqua: #0be5cb;
  --c-green: #718573;

  --f-size-1: 2.4rem;
  --f-size-2: 2rem;
  --f-size-3: 1.6rem;
  --f-size-4: 1.4rem;
  --f-size-5: 1.2rem;
  --f-size-6: 1rem;

  --f-weight-1: 700;
  --f-weight-2: 500;
  --f-weight-3: 400;

  --f-family-1: 'Raleway', sans-serif;
  --f-family-2: 'Ubuntu', sans-serif;
}

/* Global HTML */
html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  font-family: var(--f-family-1);
  background: linear-gradient(180deg, #1f2944 0%, #080b12 100%) no-repeat;
}

main {
  /* border: 1px solid green; */
  min-height: 100vh;
  width: 100%;
  /* max-width: 36rem; */
  margin: 0 auto;
  color: white;
}

a {
  text-decoration: none;
}

img {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1rem;
  font-weight: normal;
}

@media only screen and (min-width: 580px) {
  body {
    background: white;
  }
}