.onboarding__outlet {
	/* border: 1px solid blue; */
	/* height: 2000px; */
}

.onboarding__outlet .onboarding__outlet-head {
	/* border: 1px solid magenta; */
	font-size: var(--f-size-3);
	font-weight: var(--f-weight-1);
}

.onboarding__outlet .onboarding__outlet-form {
	/* border: 1px solid magenta; */
	margin-top: 1.6rem;
}

.onboarding__outlet .onboarding__outlet-form .onboarding__form {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

/* test */
.onboarding__form .form-input__input-email,
.onboarding__form .form-input__input-number {
	position: relative;
	margin-top: 1rem;
}

.onboarding__form .form-input__input-number {
	width: 100%;
}


.onboarding__form .form-input__input-email .input__text,
.onboarding__form .form-input__input-number .input__text {
	margin: 0;
}

.onboarding__form .form-input__input-number .input__text {
	width: 100%;
}

.onboarding__form .form-input__input-email .form-input__input-email-verify {
	position: absolute;
	right: 0; top: 0; bottom: 0;
	background: rgba(255, 199, 0, 0.1);
	border: none;
	outline: none;
	color: var(--c-yellow);
	border-radius: 10rem;
	font-size: var(--f-size-5);
	margin: .7rem 1.4rem;
	padding: .6rem 1rem;
}

.onboarding__form .form-input__input-email .form-input__check-icon,
.onboarding__form .form-input__input-number .form-input__check-icon {
	position: absolute;
	right: 0; top: 0; bottom: 0;
	/* border: 1px solid magenta; */
	height: 100%;
	margin-right: 1.4rem;
	display: flex;
	align-items: center;
	width: 1.6rem;
}

.onboarding__form .form-input__input-email .form-input__check-icon svg,
.onboarding__form .form-input__input-number .form-input__check-icon svg {
	width: 100%;
	height: 100%;
}

.onboarding__form .form-input__input-email .form-input__check-icon svg path,
.onboarding__form .form-input__input-number .form-input__check-icon svg path {
	fill: var(--c-yellow);
}

/* test */


.onboarding__outlet .onboarding__outlet-retrive-container .p1 {
	font-size: var(--f-size-3);
	font-weight: var(--f-weight-1);
	margin-bottom: 1.6rem;
}

.onboarding__outlet .onboarding__outlet-retrive-container .p2 {
	font-size: var(--f-size-5);
	margin-top: 1.6rem;
	text-align: center;
}

.onboarding__outlet .onboarding__outlet-retrive {
	/* border: 1px solid magenta; */
	padding: 1.1rem 0;
	background-color: var(--c-white-2);
	border-radius: .6rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.onboarding__outlet .onboarding__outlet-retrive .onboarding__outlet-retrive-button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	border: none;
	outline: none;
	background-color: transparent;
}

.onboarding__button {
	position: absolute;
	bottom: 0; left: 0; right: 0;
	padding: 1.5rem 2rem;
	background-color: var(--c-black-2);
}
