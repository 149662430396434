.notifications__main {
  display: flex;
  flex-direction: column;
  position: relative;
}

.notifications__body {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
  padding: 2rem;
}

.notifications__body-card {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 1.1rem 1.4rem;
  gap: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.6rem;
  font-family: var(--f-family-2);
}

.notifications__card-date-time {
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 60%;
  gap: 0.6rem;
}

.notifications__card-date-time span {
  width: 0.2rem;
  height: 0.2rem;

  background: rgba(255, 255, 255, 0.6);
}

.notifications__card-title {
  font-weight: 500;
}

.notifications__card-desc {
  font-weight: 500;
  line-height: 1.6rem;
}

.notifications__card-desc span {
  color: #ffc700;
}

.notifications__card-button button {
  padding: 1.2rem 1.4rem;
  background: rgba(255, 199, 0, 0.1);
  border-radius: 0.6rem;
  color: #ffc700;
  width: fit-content;
  border-width: 0;
  font-weight: 700;
}

.notifications__assets {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  background: #1c263e;
  box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.25);
  padding: 1rem 2rem;
  gap: 1.2rem;
}

.notifications__assets-image-selected {
  position: relative;
}

.notifications__assets-image-selected img {
  position: relative;
  opacity: 60%;
  width: 8rem;
  height: 8rem;
  border-radius: 0.6rem;
}

.assets-image-selected-text {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 20%;
  top: 30%;
  color: #ffc700;
  font-weight: 500;
}

.assets-image-selected-text span {
  width: 1.6rem;
  height: 0.8rem;
  background: #ffc700;
  border-radius: 9.9rem;
}

.notifications__assets-image img {
  position: relative;
  width: 8rem;
  height: 8rem;
  border: 0.1rem solid #ffc700;
  border-radius: 0.6rem;
}
