.search-body,
.search-body__height {
  /* border: 1px solid red; */
  /* padding: 2rem; */
  display: flex;
}

.browse-location {
  /* display: grid; */
  /* grid-template-columns: 1fr; */
  /* grid-template-rows: auto 1fr; */
  display: flex;
  flex-direction: column;
  /* border: 1px solid magenta; */
  height: 100vh;
}

.search-body__height {
  /* height: calc(100vh - 14.9rem); */
  /* height: 100vh; */
  /* flex: 1; */
  height: 100%;
  /* border: 1px solid magenta; */
  position: relative;
  overflow-y: auto;
}

.search-body__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 12px; */
  /* padding: 2rem; */
}

.search-body__container .accordion__wrapper {
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
}

.search-body__accordion {
  width: 100%;
  /* padding: 11px 14px; */
  background-color: var(--c-navy);
  border-radius: 6px;
}

.search-body__accordion-yellow {
  width: 100%;
  /* padding: 11px 14px; */
  background-color: rgba(255, 199, 0, 0.1);
  border-radius: 6px;
}

.search-body__accordion-title {
  display: flex;
  padding: 11px 14px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}

/* .search-body__accordion-yellow .search-body__accordion-title {
  padding: 0;
} */

.search_accordion__cover {
  display: flex;
  gap: 6px;
  align-items: center;
}

.search-accordion__code {
  padding: 2px 5px;
  border-radius: 100px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-accordion__line-code,
.search-accordion__line-name {
  font-weight: var(--f-weight-1);
}

.search-accordion__line-name {
  font-size: 1.6rem;
}

.search-accordion__line-code {
  font-size: 1.2rem;
}

.search-accordion__line-name-yellow {
  font-weight: var(--f-weight-1);
  color: var(--c-yellow);
  font-size: 1.6rem;
}

.search-accordion__station-checkboxes {
  display: flex;
  padding: 11px 14px;
  padding-top: 0;
  flex-direction: column;
}

.search-accordion__checkboxes-container {
  display: flex;
  align-items: center;
  padding: 9px 0;
  gap: 12px;
}

.station__label {
  display: flex;
  gap: 4px;
  user-select: none;
  cursor: pointer;
}

.station__name {
  width: 120px;
  font-size: 1.4rem;
}

.station__line-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.station__line-color {
  width: 16px;
  height: 8px;
  border-radius: 99px;
}

.hide {
  display: none;
}

.drop-icon__yellow path {
  fill: var(--c-yellow);
}

/* input[type='checkbox'] {
  position: relative;
  background-color: rgba(255, 199, 0, 0.1);
  accent-color: var(--c-white-1);
  border: 2px solid white;
  border-radius: 2px;
  height: 12px;
  width: 12px;
  -webkit-appearance: none;
} */

/* input[type='checkbox']:checked {
  background-color: var(--c-white-2);
  background-color: white;
  border: 2px solid var(--c-yellow);
} */

/* input[type='checkbox']:checked ~ input[type='checkbox']::before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 10px;
  border: solid var(--c-yellow);
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
} */

.search-body__districts {
  display: flex;
  flex-direction: column;
  padding: 13px 16px;
  border-radius: 6px;
  width: 100%;
  background-color: var(--c-navy);
  gap: 1.6rem;
  /* border: 1px solid magenta; */
}

.search-body__diswrapper {
  padding: 2rem;
  /* border: 1px solid green; */
  overflow-y: auto;
}

.search-body__districts-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.search-body__districts-item input[type='checkbox']:checked ~ label,
.search-accordion__checkboxes-container input[type='checkbox']:checked ~ label {
  color: var(--c-yellow);
}

.districts-code,
.districts-name {
  font-family: var(--f-family-2);
}

.districts-code {
  padding: 2px 5px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 99px;
  display: flex;
  max-height: 1.8rem;
  align-items: center;
  font-weight: var(--f-weight-1);
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.6);
}

.districts-name {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  cursor: pointer;
  line-height: 2rem;
}
.search__postal {
  /* padding: 2rem; */
  display: flex;
  flex-direction: column;
  /* gap: 2rem; */
  /* height: 100%; */
  flex: 1;
}

.search__postal .search__postal-form {
  /* border: 1px solid magenta; */
  /* background-color: red; */
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.search-body__search-postal {
  display: flex;
  gap: 8px;
  align-items: center;
  /* border: 1px solid magenta; */
  width: 100%;
  /* justify-content: center; */
  padding: 2rem;
}

.search-body__search-postal h1 {
  font-size: 14px;
  font-weight: var(--f-weight-1);
}

.search-body__search-postal-select {
  padding: 12px 14px;
  background-color: unset;
  color: white;
  border: 1px solid white;
  border-radius: 6px;
  font-weight: var(--f-weight-1);
}

.search-body__search-postal-input {
  padding: 12px 14px;
  max-width: 109px;
  background-color: unset;
  border: 1px solid white;
  border-radius: 6px;
  color: white;
  outline: none;
}

.search-body__search-postal-input::placeholder {
  color: white;
  font-size: 1.4rem;
  font-weight: var(--f-weight-1);
}

.search-postal__map-container {
  /* height: calc(100vh - 182px); */
  overflow: hidden;
  /* border: 1px solid green; */
  height: 100%;
  /* background-color: blue; */
  position: relative;
}

.search-postal__map-container img {
  height: 100%;
  /* object-fit: cover; */
  /* width: 100%; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
}

.search-apply {
  width: 100%;
  padding: 15px 36px;
  background-color: black;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  gap: 16px;
  justify-content: center;
}

.search-apply__btn {
  display: flex;
  width: auto;
  color: white;
  /* padding: 12px 14px; */
  padding: 0 1.4rem;
  height: 4rem;
  align-items: center;
  justify-content: center;
  gap: 11px;
  border-radius: 6px;
  border: none;
  outline: none;
  flex: 1;
  cursor: pointer;
}

.search-apply .search__reset {
  position: relative;
}

.search-apply .search__reset input[type='reset'] {
  display: flex;
  width: auto;
  color: white;
  align-items: center;
  justify-content: center;
  padding: 0 1.4rem 0 3.4rem;
  gap: 11px;
  border-radius: 6px;
  border: none;
  outline: none;
  height: 4rem;
  font-size: var(--f-size-5);
  font-weight: var(--f-weight-1);
  font-family: var(--f-family-1);
  background-color: rgba(255, 255, 255, 0.05);
  /* border: 1px solid magenta; */
  cursor: pointer;
}

.search-apply .search__reset svg {
  position: absolute;
  top: 0;
  left: 1.4rem;
  bottom: 0;
  margin: auto 0;
}

.close-btn {
  background-color: #ffffff0d;
}

.apply-btn {
  background-color: #ffc7001a;
  color: var(--c-yellow) !important;
  width: 100%;
}

.search-apply__btn span {
  font-size: var(--f-size-5);
  font-weight: var(--f-weight-1);
  font-family: var(--f-family-1);
}

.search-apply__icon-clear path {
  height: 14px;
}

.search-apply__icon-apply path {
  fill: var(--c-yellow);
}

.browse-input--checkbox {
  /* margin-top: 1.2rem; */
}

.browse-input--checkbox label {
  /* border: 1px solid magenta; */
  display: flex;
  gap: 1.2rem;
  position: relative;
  padding-left: 2rem;
  color: var(--c-yellow);
}

.browse-input--checkbox input[type='checkbox'] {
  /* border: 1px solid magenta; */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
}

.browse-input--checkbox .checkbox-mark {
  /* border: 1px solid magenta; */
  width: 1.2rem;
  height: 1.2rem;
  background-color: none;
  border: 1px solid var(--c-yellow);
  border-radius: 0.2rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: .1rem; */
}

.browse-input--checkbox .checkbox-mark svg {
  opacity: 0;
  transform: translateY(-0.1rem);
}

.browse-input--checkbox .checkbox-mark svg path {
  fill: var(--c-yellow);
}

.browse-input--checkbox input[type='checkbox']:checked ~ .checkbox-mark svg {
  opacity: 1;
}

/* USED IN LIST NEW PROPERTY */
.browse-input--checkbox_normal label {
  /* border: 1px solid magenta; */
  color: var(--c-white);
}

.browse-input--checkbox_normal .checkbox-mark {
  /* background-color: var(--c-navy); */
  border: 1px solid var(--c-white);
}

.browse-input--checkbox_normal .checkbox-mark-addInv {
  background-color: transparent;
}

.browse-input--checkbox_normal input[type='checkbox']:checked ~ .checkbox-mark {
  border: 1px solid var(--c-yellow);
}

.browse-input--checkbox_normal
  input[type='checkbox']:checked
  ~ .amenities-label {
  color: var(--c-yellow) !important;
}
