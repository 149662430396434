.onboarding__outlet-2 {
	/* border: 1px solid magenta; */
}

.onboarding__2-form {
	display: flex;
	flex-direction: column;
	gap: 4rem;
}

.onboarding__outlet-2-identity {
	/* border: 1px solid magenta; */
}

.onboarding__outlet-2-head {
	/* border: 1px solid magenta; */
	margin-bottom: 1.6rem;
}

.onboarding__outlet-2-head h1 {
	/* border: 1px solid magenta; */
	font-size: var(--f-size-3);
	font-weight: var(--f-weight-1);
}

.onboarding__outlet-2-head .p1 {
	margin-top: .2rem;
	font-size: var(--f-size-5);
}

.onboarding__outlet-2-photo {
	/* border: 1px solid magenta; */
}

.onboarding__outlet-2-photo .onboarding__outlet-2-file {
	/* border: 1px solid magenta; */
	position: relative;
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.onboarding__outlet-2-photo .onboarding__outlet-2-file input {
	/* border: 1px solid magenta; */
	/* width: 100%; */
  /* height: 100%; */
  opacity: 0;
  overflow: hidden;
	position: absolute;
	z-index: -2;
	/* top: 0; left: 0; right: 0; bottom: 0; */
}

.onboarding__outlet-2-photo .onboarding__outlet-2-file label {
	/* background-color: red; */
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1.2rem;
	padding: 1.5rem 0;
	font-size: var(--f-size-3);
	border-radius: .6rem;
	background: rgba(255, 199, 0, 0.1);
	color: var(--c-yellow);
	font-weight: var(--f-weight-1);
}

.onboarding__outlet-2-photo .onboarding__outlet-2-file label svg path {
	fill: var(--c-yellow);
}

.onboarding__outlet-2-file-preview {
	/* border: 1px solid magenta; */
	padding: .9rem .8rem;
	background-color: var(--c-white-2);
	margin-top: 1.6rem;
	display: flex;
	gap: .6rem;
	flex-wrap: wrap;
}

.onboarding__outlet-2-file-preview .image__preview {
	/* border: 1px solid magenta; */
	width: 4.2rem;
	height: 4.2rem;
	border-radius: .3rem;
	position: relative;
	overflow: hidden;
	background-color: var(--c-yellow);
}

.onboarding__outlet-2-file-preview .image__preview-delete {
	position: absolute;
	background-color: black;
	width: 2.6rem;
	height: 2.6rem;
	border-radius: 10rem;
	right: calc(-1.6rem / 1.6); top: calc(-1.6rem / 1.6);
}

.onboarding__outlet-2-file-preview .image__preview-delete svg {
	transform: scale(.9) translateX(.45rem) translateY(1.3rem);
}

.onboarding__outlet-2-file-preview .image__preview img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.onboarding__outlet-2-file-preview--hide {
	display: none;
}