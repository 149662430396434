.lease__t-body-outlet {
	/* border: 1px solid magenta; */
	/* height: 100vh; */
	display: flex;
	flex-direction: column;
	gap: 1.3rem;
	padding: 2rem;
}

.lease__t-body-outlet-1 {
	/* border: 1px solid magenta; */
	/* height: 100vh; */
	padding: 2rem;
	padding-right: 0;
}

.lease__t-body-outlet-1 .lease__outlet-1-splide .lease__outlet-1-slide  {
	width: 32rem !important;
}

.lease__t-body-outlet .outlet__head {
	/* border: 1px solid magenta; */
	display: flex;
	gap: .8rem;
	/* justify-content: space-between; */
	align-items: center;
}

.lease__t-body-outlet .outlet__head h1 {
	font-size: var(--f-size-2);
	font-weight: var(--f-weight-1);
}

.lease__t-body-outlet .outlet__head .head__count {
	padding: .3rem 1rem;
	background-color: rgba(255, 199, 0, 0.1);
	font-size: var(--f-size-3);
	color: var(--c-yellow);
	border-radius: 10rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: var(--f-family-2);
}

.lease__t-body-outlet .outlet__body {
	/* border: 1px solid magenta; */
	/* margin-top: 1.3rem; */
	padding: 2.5rem 1.8rem;
	background-color: var(--c-white-2);
	border-radius: .6rem;
}
