.lease-chat-list-container {
  /* margin-top: 0.4rem; */
  padding: 3rem 2rem 1rem 2rem;
  min-height: 10rem;
  background: #1c263e;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  /* border: 1px solid magenta; */
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
	z-index: 1;
	position: relative;
}

.lease-chat-list-container .lease-chat-wrapper {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  /* border: 1px solid white; */
}

.lease-chat-list-container .box-chat-lease {
  /* width: 80px; */
  /* height: 80px; */
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  /* border-radius: 6px; */
  cursor: pointer;
  /* margin-bottom: 1rem; */
  overflow: hidden;
  gap: 1rem;
  /* border: 1px solid magenta; */
  max-width: 100%;
}

.lease-chat-list-container .box-chat-lease .box__lease {
  /* width: 8rem; */
  /* height: 8rem; */
  border-radius: .6rem;
  overflow: hidden;
  position: relative;
  aspect-ratio: 1 / 1;
	text-align: center;
	font-size: var(--f-size-5);

	color: rgba(255, 255, 255, 0.6);
	font-family: var(--f-family-2);
	font-weight: var(--f-weight-2);
}

.lease-chat-list-container .box-chat-lease img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  /* border: 1px solid magenta; */
}

.lease-chat-list-container .box-chat-lease .box__lease .overlay {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: #00000099;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.2rem;
}

.lease-chat-list-container .box-chat-lease .box__lease--active {
	border: 1px solid var(--c-yellow);
}

.lease-chat-list-container .box-chat-lease .box__lease--active .overlay {
	background-color: transparent;
	color: black;
	font-family: var(--f-family-2);
	font-weight: var(--f-weight-2);
}

.chat-user-row {
  background: #1c263e;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  display: flex;
  gap: 1rem;
	/* position: relative; */
	/* z-index: -1; */
	padding: 1rem 2rem;
	/* border: 1px solid magenta; */
}

.chat-user-item {
  position: relative;
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--c-white-2);
	aspect-ratio: 1 / 1;
}

.chat-user-item.chat-user-item_active {
  background: rgba(255, 199, 0, 0.1);
  color: var(--c-yellow);
}

.chat-user-item_name {
  font-family: var(--f-family-2);
  font-weight: var(--f-weight-2);
}

.chat-indicator {
  position: absolute;
  background: #FC682D;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 99px;
  top: 0;
  right: 0;
}

.chat-user-slider {
	width: 100%;
	/* border: 1px solid blue; */
}

.lease-chat-list-container .splide,
.chat-user-slider .splide {
  /* border: 1px solid magenta !important; */
  width: 100%;
}

.lease-chat-list-container .splide__list,
.chat-user-slider .splide__list {
  display: flex;
  gap: 1.2rem;
}

.lease-chat-list-container .splide__slide {
  width: 8rem !important;
  height: 8rem !important;
  margin: 0 !important;
  /* border: 1px solid red !important; */
}

.chat-user-slider .splide__slide {
  width: 2.8rem !important;
  height: 2.8rem !important;
  margin: 0 !important;
  /* border: 1px solid red !important; */
}