.property-details {
  display: flex;
  flex-direction: column;
  padding: 2rem 0.1rem 2rem 2rem;
  gap: 2rem;
}

.property-details__main-info {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.property-details__flag {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.property-details__flag-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
}

.property-details__flag-train {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  padding: 0.2rem 0.6rem 0.2rem 0.2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 9.9rem;
  font-family: var(--f-family-2);
}

.property-details__flag-train div {
  padding: 0.2rem 0.5rem;
  border-radius: 10rem;
  background-color: var(--c-blue);
  font-size: 0.8rem;
}

.property-details__flag-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.7rem;
  padding-right: 1.9rem;
}

.property-details__flag-button button {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
}

.property-details__flag-button path {
  fill: var(--c-yellow);
}

.property-details__name-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.property-details__property-name {
  width: 100%;
  max-width: 18rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  font-weight: 700;
}

.property-details__property-name div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.property-details__property-name .text-sm {
  font-weight: 400;
}

.property-details__property-name .text-sm span,
.property-details__property-name path {
  color: var(--c-yellow);
  fill: var(--c-yellow);
}

.property-details__property-name svg {
  width: 0.863rem;
  height: 1.087rem;
}

.property-details__property-price {
  flex: 1 1;
  text-align: right;
  font-weight: 500;
  font-family: var(--f-family-2);
  padding-right: 1.9rem;
}

.property-details__property-price span {
  font-size: var(--f-size-6);
}

.property-details__feature,
.property-details__insight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.property-details__feature-icon,
.property-details__insight-icon {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 0.2rem;
}

.property-details__insight-icon {
  align-items: center;
  opacity: 60%;
}

.property-details__info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1rem;
  position: relative;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.property-details__info::-webkit-scrollbar {
  display: none;
}

.property-details__info-point {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  width: 100%;
  padding: 0.8rem 1rem;
  gap: 0.4rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.6rem;
  font-weight: 500;
}

.property-details__info-point svg {
  width: 1.3rem;
  height: 1.3rem;
}

.property-details__highlight {
  display: flex;
  flex-direction: column;
  padding: 1.3rem 1.4rem;
  margin-right: 1.9rem;
  gap: 1rem;
  background: rgba(255, 199, 0, 0.1);
  border-radius: 0.6rem;
  color: var(--c-yellow);
}

.property-details__highlight-subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
}

.property-details__highlight-subtitle path {
  fill: var(--c-yellow);
}

.property-details__FAQ {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding-right: 1.9rem;
  font-weight: 700;
}

.property-details__FAQ .faq__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.property-details__FAQ-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.1rem 1.4rem;
  gap: 0.6rem;
  width: 100%;
  position: relative;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.6rem;
}

.property-details__FAQ-container svg {
  margin-left: auto;
}

.property-details__FAQ-container p{
  max-width: 70%;
}