.password__main {
  display: flex;
  flex-direction: column;
  position: relative;
}

.password__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  padding-bottom: 8.8rem;
  gap: 4rem;
  height: calc(100vh - 6.4rem);
  overflow-y: hidden;
}

.password__body-password {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.password__body-password .form-input {
  font-family: var(--f-family-2);
}

.password__body-password .form-input label{
  font-weight: 500;
}

.password__body .text-lg {
  font-weight: 700;
}

.input__password-box {
  position: relative;
}

.input__password-box input.input__text{
  font-weight: 500;
  color: var(--c-white);
  opacity: 100%;
  font-family: var(--f-family-2);
}

.input__eye-icon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 110%;
  margin-right: 1.4rem;
  display: flex;
  align-items: center;
  width: 1.6rem;
  cursor: pointer;
}

.password__requirement {
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;
  padding: 0.6rem 1rem;
  gap: 0.4rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 9.9rem;
  margin-top: 0.6rem;
}

.validated {
  color: #0be5cb;
  opacity: 100%;
}
.not-validated {
  font-weight: 400;
  opacity: 60%;
  color: #ffffff;
}

.password__body-auth {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  width: 100%;
}

.password__body-auth-opt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;
}

.auth-opt-subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.auth-opt-subtitle p {
  font-weight: 700;
}

.auth-opt-switch {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: fit-content;
  font-family: var(--f-family-2);
}

.auth-opt-switch input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 0.1rem;
  width: 0.1rem;
  border: 0;
  overflow: hidden;
}

.auth-opt-switch label {
  background-color: rgba(255, 255, 255, 0.05);
  color: #bbbbbb;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  padding: 0.6rem 1rem;
  margin-right: -0.1rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.auth-opt-switch label:first-of-type {
  border-radius: 0.6rem 0 0 0.6rem;
}

.auth-opt-switch label:last-of-type {
  border-radius: 0 0.6rem 0.6rem 0;
}

.auth-opt-switch label:hover {
  cursor: pointer;
}

.auth-opt-switch input:checked + label {
  background-color: #ffc700;
  color: #1f2944;
  font-weight: 500;
}

.password__body-auth-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.6rem 1.5rem;
  gap: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.6rem;
  width: 100%;
  position: relative;
}

.password__body-auth-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.6rem;
  opacity: 60%;
  font-size: 1.2rem;
  font-family: var(--f-family-2);
}

.password__button {
  position: absolute;
  display: inline-block;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: var(--c-black-2);
}
