.viewing-page {
  padding: 2rem 2rem 10rem 2rem;
}

.requirements-container,
.tenant-profile-container {
  padding: 1.1rem 1.4rem;
  background: var(--c-white-2);
  margin: 1.6rem 0;
  border-radius: 0.6rem;
}

.timeslot-container {
  margin-top: 2rem;
}

.timeslot-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
  margin: 1rem 0;
}

.timeslot-card-item {
  width: 143px;
  gap: 1rem;
  padding: 0.8rem 1rem;
  display: flex;
  text-align: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.6rem;
  align-items: center;
  font-family: var(--f-family-2);
  cursor: pointer;
}

.disabled-filter {
  opacity: 0.6;
  pointer-events: none;
}

.timeslot-date {
  flex: 1;
}

.timeslot-time {
  flex: 1.5;
  text-align: left;
}

.timeslot-selected {
  background: rgba(255, 199, 0, 0.1);
  color: var(--c-yellow);
}

.timeslot-day {
  margin: 0.5rem 0;
  background: rgba(255, 199, 0, 0.1);
  border-radius: 99px;
  color: var(--c-yellow);
}

.timeslot-occupied-full {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--c-red);
  margin: 0.5rem 0;
}

.timeslot-occupied-available {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0;
  color: #0be5cb;
}

.slot-indicator-available {
  width: 16px;
  height: 8px;
  border-radius: 99px;
  background: #0be5cb;
}

.slot-indicator-full {
  width: 16px;
  height: 8px;
  border-radius: 99px;
  background: var(--c-red);
  color: var(--c-red);
}

.timeslot-time {
  color: var(--c-white);
}

.schedule-button {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem 2rem;
  background-color: var(--c-black-2);
  position: fixed;
}

.pax-container {
  margin-top: 2rem;
}

.tenant-profile-form {
  margin: 2rem 0;
}
