.lease-add-list-container {
  /* margin-top: 0.4rem; */
  padding: 1rem 1.5rem;
  min-height: 10rem;
  background: #1c263e;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  /* border: 1px solid magenta; */
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.lease-add-list-container .lease-add-wrapper {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  /* border: 1px solid white; */
}

.lease-add-list-container .box-add-lease {
  /* width: 80px; */
  /* height: 80px; */
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  /* border-radius: 6px; */
  cursor: pointer;
  /* margin-bottom: 1rem; */
  overflow: hidden;
  gap: 1rem;
  /* border: 1px solid magenta; */
  max-width: 100%;
}

.lease-add-list-container .box-add-lease .box__lease {
  /* width: 8rem; */
  /* height: 8rem; */
  border-radius: .6rem;
  overflow: hidden;
  position: relative;
  aspect-ratio: 1 / 1;
}

.lease-add-list-container .box-add-lease img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  /* border: 1px solid magenta; */
}

.lease-add-list-container .box-add-lease .box__lease .overlay {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: #00000099;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.2rem;
}

.lease-add-list-container .box-add-lease .box__lease .overlay .overlay__content {
  display: flex;
  flex-direction: column;
  gap: .4rem;
}

.lease-add-list-container .box-add-lease .box__lease .overlay .overlay__content .label {
  width: 1.6rem;
  height: .8rem;
  background-color: white;
  border-radius: .6rem;
}

.lease-add-list-container .box-add-lease .box__lease .overlay .overlay__content--pending {
  color: var(--c-yellow);
}

.lease-add-list-container .box-add-lease .box__lease .overlay .overlay__content--offer {
  color: var(--c-aqua);
}

.lease-add-list-container .box-add-lease .box__lease .overlay .overlay__content--pending .label {
  background-color: var(--c-yellow);
}

.lease-add-list-container .box-add-lease .box__lease .overlay .overlay__content--offer .label {
  background-color: var(--c-aqua);
}

.lease-add-list-container .btn-add-lease {
  width: 8rem;
  height: 8rem;
  aspect-ratio: 1 / 1;
  border-radius: .6rem;
  outline: 0;
  border: none;
  background-color: rgba(255, 199, 0, 0.1);
  cursor: pointer;
}

.lease-add-list-container .btn-add-lease svg {
  width: 1.4rem;
  height: 1.4rem;
}

.lease-add-list-container .btn-add-lease svg path {
  fill: var(--c-yellow);
}

.lease-add-list-container .lease-add-actions {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  /* border: 1px solid white; */
}

.lease-add-list-container .splide {
  /* border: 1px solid magenta !important; */
  width: 100%;
}

.lease-add-list-container .splide__list {
  display: flex;
  gap: 1.2rem;
}

.lease-add-list-container .splide__slide {
  width: 8rem !important;
  height: 8rem !important;
  margin: 0 !important;
  /* border: 1px solid red !important; */
}