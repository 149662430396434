
.lease__t-body-outlet .outlet__body .box__info {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	/* border: 1px solid magenta; */
}

.lease__t-body-outlet .outlet__body .box__info svg {
	width: 100%;
	/* border: 1px solid magenta; */
	margin-bottom: 2rem;
}

.lease__t-body-outlet .outlet__body .box__info h1 {
	font-size: var(--f-size-2);
	font-weight: var(--f-weight-1);
	margin-bottom: 1rem;
}

.lease__t-body-outlet .outlet__body .box__info p {
	font-size: var(--f-size-5);
}