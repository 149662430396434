.search-browse {
  background-color: var(--c-navy);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  padding: 3rem 2rem 1rem;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  top: 0;
  position: sticky;
}

.search-browse__input_container,
.search-browse__icons-container,
.search-browse__list-info-container {
  width: 100%;
}

/* Search Input */
.search-browse__input-container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  gap: 1.6rem;
}

.search-browse__search-input {
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
  padding: 0 1.2rem;
  border-radius: 0.6rem;
  border: 1px solid var(--c-white);
  /* cursor: pointer; */
  width: 100%;
  height: 4rem;
}

.search-input__lens {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid white; */
  height: 2rem;
  width: 2rem;
}

.search-input__input {
  border: none;
  outline: none;
  background: none;
  padding: 0.5rem;
  color: var(--c-white);
  font-size: 1.4rem;
}

.search-input__input::placeholder {
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--c-white);
}

.search-input__navigation svg path {
  fill: var(--c-yellow);
}

.search-browse__filter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: var(--c-white-2);
  height: 100%;
  padding: 1.2rem 1.4rem;
  border-radius: 6px;
  cursor: pointer;
}

.search-browse__filter h1 {
  font-size: 1.4rem;
  font-weight: var(--f-weight-1);
}

/* Search Icons */
.search-browse__icons-container {
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
}

.search-browse__icons {
  background-color: var(--c-white-2);
  padding: 7px 13px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 38px; */
}

.search-browse__svg path {
  transform: scale(1);
  fill: var(--c-white-3);
}

/* Search Listing Information */
.search-browse__list-info-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.search-browse__icons-container .splide {
  /* border: 1px solid magenta; */
  width: 100%;
}

.search-browse__list-card {
  display: flex;
  gap: .8rem;
  padding: 0 1.1rem;
  background-color: var(--c-white-2);
  border-radius: 99px;
  align-items: center;
  height: 2.6rem;
}

.search-browse__list-card h1,
.search-browse__list-filter h1 {
  font-weight: var(--f-weight-2);
  font-family: var(--f-family-2);
  font-size: 1.2rem;
}

.search-browse__list-filter {
  background-color: rgba(255, 199, 0, 0.1);
  display: flex;
  padding: 0 1.1rem;
  border-radius: 99px;
  align-items: center;
  height: 2.6rem;
  gap: .8rem;
}

.search-browse__list-filter h1 {
  color: var(--c-yellow);
}

.search-browse__list-filter-icon path {
  fill: var(--c-yellow);
}

@media only screen and (min-width: 580px) {
  .search-browse__filter {
    background-color: rgba(31, 41, 68, 0.1);
    color: var(--c-navy);
  }

  .search-browse__filter svg path {
    fill: var(--c-navy);
  }

  .search-browse__list-info-container {
    display: none;
  }

  .search-browse__search-input {
    border: 1px solid var(--c-navy);
  }

  .search-input__input {
    color: var(--c-navy);
  }
  
  .search-input__input::placeholder {
    color: var(--c-navy);
  }
  
  .search-input__navigation svg path {
    fill: var(--c-navy);
  }

  .search-input__lens svg path {
    fill: var(--c-navy);
  }
  
}
