.marketdata {
	padding: 2rem;
	/* border: 1px solid magenta; */
	height: calc(100vh - (7.4rem + 7.1rem));
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.marketdata .marketdata__search {
	/* border: 1px solid magenta; */
}

.marketdata__search .search__title {
	font-size: var(--f-size-2);
	font-weight: var(--f-weight-1);
}

.marketdata__search .search__info {
	/* border: 1px solid magenta; */
	display: flex;
	flex-direction: column;
	gap: 2rem;
	background-color: var(--c-white-2);
	padding: 1.4rem;
	border-radius: .6rem;
	margin-top: 1.4rem;
}

.marketdata__search .search__form {
	/* border: 1px solid magenta; */
	display: flex;
	flex-direction: column;
	gap: 1.4rem;
}

.marketdata__search .form__wrapper {
	/* border: 1px solid magenta; */
	display: flex;
	gap: 1.4rem;
}

.marketdata__search .form-input label {
	/* border: 1px solid magenta; */
	font-family: var(--f-family-2);
}

.marketdata__search .form-input input {
	width: 100%;
}

.marketdata__search .search__form input[type='submit'] {
	/* border: 1px solid magenta; */
	max-width: unset;
	font-size: var(--f-size-4);
}


.marketdata__search .current__manage .manage__title {
	/* border: 1px solid magenta; */
	font-size: var(--f-size-3);
	font-weight: var(--f-weight-1);
}

.marketdata__search .current__manage .manage__box {
	gap: 1.2rem;
}

.marketdata__chart {
	/* background-color: var(--c-white-2); */
	/* border-radius: .6rem; */
	/* border: 1px solid magenta; */
	/* padding: 1.4rem; */
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.marketdata__chart .chart__display {
	display: flex;
	flex-direction: column;
	gap: 1.4rem;
}

.marketdata__chart .chart__display .chart__display-title {
	font-size: var(--f-size-2);
	font-weight: var(--f-weight-1);
}

.marketdata__chart .chart__wrapper {
	/* border: 1px solid magenta; */
	background-color: var(--c-white-2);
	border-radius: .6rem;
	padding: 1.4rem;
}

.marketdata__chart .chart__wrapper .chart__line {
	height: 25rem;
}

.marketdata__chart .chart__wrapper .chart__label {
	/* border: 1px solid magenta; */
	display: flex;
	justify-content: space-between;
	margin-top: 1.4rem;
}
.marketdata__chart .chart__wrapper .chart__label .label {
	/* border: 1px solid magenta; */
	display: flex;
	align-items: center;
	gap: .2rem;
	font-family: var(--f-family-2);
}

.marketdata__chart .chart__wrapper .chart__label .label__badge {
	width: 1.5rem;
	border-radius: 99rem;
	height: .8rem;
	background-color: white;
}

.marketdata__chart .chart__wrapper .chart__label .label__badge-1 {
	background-color: var(--c-yellow);
}
.marketdata__chart .chart__wrapper .chart__label .label__badge-2 {
	background-color: var(--c-blue-1);
}
.marketdata__chart .chart__wrapper .chart__label .label__badge-3 {
	background-color: var(--c-orange);
}
.marketdata__chart .chart__wrapper .chart__label .label__badge-4 {
	background-color: var(--c-aqua);
}
.marketdata__chart .chart__wrapper .chart__label .label__badge-5 {
	background-color: var(--c-purple-1);
}
