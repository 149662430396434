.addCard {
  position: relative;
  height: 100vh;
}

.addCard__container {
  /* border: 1px solid magenta; */
  padding: 2rem;
}

.addCard__body {
  /* border: 1px solid magenta; */
  border-radius: 0.6rem;
  padding: 1.1rem;
  background-color: var(--c-white-2);
}

.addCard__field {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.addCard__row {
  display: flex;
  /* border: 1px solid magenta; */
  width: 70%;
  gap: 1.2rem;
}

.addCard__input-number {
  position: relative;
  /* border: 1px solid magenta; */
  margin-top: 1.2rem;
}

.addCard__field .form-input label {
  font-size: var(--f-size-5);
}

.addCard__field input {
  font-family: var(--f-family-2) !important;
}

.addCard__input-number input {
  margin: 0 !important;
  font-family: var(--f-family-2) !important;
}

.addCard__input-number svg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1.2rem;
  margin: auto 0;
}

.form-input--checkbox {
  margin-top: 1.2rem;
}

.form-input--checkbox label {
  /* border: 1px solid magenta; */
  display: flex;
  gap: 1.2rem;
  position: relative;
  padding-left: 2.4rem;
  color: var(--c-yellow);
}

.form-input--checkbox input[type='checkbox'] {
  /* border: 1px solid magenta; */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
}

.form-input--checkbox .checkbox-mark {
  /* border: 1px solid magenta; */
  width: 1.2rem;
  height: 1.2rem;
  background-color: var(--c-navy);
  border: 1px solid var(--c-yellow);
  border-radius: 0.2rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: .1rem; */
}

.form-input--checkbox .checkbox-mark svg {
  opacity: 0;
  transform: translateY(-0.1rem);
}

.form-input--checkbox .checkbox-mark svg path {
  fill: var(--c-yellow);
}

.form-input--checkbox input[type='checkbox']:checked ~ .checkbox-mark svg {
  opacity: 1;
}

/* USED IN LIST NEW PROPERTY */
.form-input--checkbox_normal label {
  /* border: 1px solid magenta; */
  color: var(--c-white);
}

.form-input--checkbox_normal .checkbox-mark {
  background-color: var(--c-navy);
  border: 1px solid var(--c-white);
}

.form-input--checkbox_normal .checkbox-mark-addInv {
  background-color: transparent;
}

.form-input--checkbox_normal input[type='checkbox']:checked ~ .checkbox-mark {
  border: 1px solid var(--c-yellow);
}

.form-input--checkbox_normal input[type='checkbox']:checked ~ .amenities-label {
  color: var(--c-yellow) !important;
}
